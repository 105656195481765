<template>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-12">
                <form class="user">
                    <div class="row">
                        <div class="col-lg-1"></div>
                            <div class="col-lg-5">
                                <div class="form-group">
                                    <label class="col-lg-12">Usuario Solicitante</label>
                                    <input class="form-control form-control-user input" type="text" id="buscarUsuarioSolicitante" :disabled="true">
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="form-group">
                                    <label class="col-lg-12">Usuario Ejecutante</label>
                                    <input class="form-control form-control-user input" type="text" id="buscarUsuarioEjecutante" :disabled="true">
                                </div>
                            </div>
                            <div class="col-lg-1"></div>
                    </div>
 
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-5">
                            <div class="form-group">
                                <label class="col-lg-12">Experiencia</label>
                                <input class="form-control form-control-user input" type="text" id="buscarExperiencia" :disabled="true">
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label class="col-lg-12">Fecha</label>
                                <input class="form-control form-control-user input" type="date" id="fechaTurno" v-on:input="Validar" >
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <label class="col-lg-12" style="visibility: hidden;">Ver Turnos</label>
                            <div class="col-lg-12 text-center">
                                <a v-on:click="VerHorariosDisponibles" class="btn btn-primary btn-user btn-block">
                                    Ver Turnos
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-10">
                            <hr>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-10">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3 contenedor-flexbox">
                                    <h6 class="m-0 font-weight-bold text-primary">Seleccionar turno</h6>
                                </div>
                                <div class="card-body contenedor-flexbox scroll" style="width: 100%;">
                                    <div>
                                        <input v-for="Horario in HorariosDisponibles" :key="Horario" type="button" :id="Horario" class="button-horario" :value="Horario" v-on:click="Seleccion(Horario)">
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-5"></div>
                        <div class="col-lg-2">
                            <a class="btn btn-primary btn-user btn-block" v-on:click="ReprogramarTurno">
                                Aceptar
                            </a>
                        </div>
                        <div class="col-lg-5"></div>
                    </div>
                </form>
            </div>
        </div> 

    </div>

    <PopUpComponent v-if="MostrarPopUp" @CerrarPopUp="CerrarPopUp()"></PopUpComponent>

</template>

<script>

    import PopUpComponent from './commons/PopUpComponent.vue';
    import { appsettings } from '../../appsettings';
    import Axios from 'axios';

    export default {
        data() {
            return {
                HorarioSeleccionado: null,
                HorariosDisponibles: [],
                UrlsTurnos:[],
                FechaTurno: null,
                MostrarPopUp: false,
                Turno: {},
                TurnoAReprogramar: {}    
            };
        },
        created(){
            //Se traen urls del modulo a gestionar
            appsettings.UrlsBackend.forEach(modulo => {
                if ("Turnos" in modulo) {
                    this.UrlsTurnos = modulo["Turnos"];
                }
            });

            this.TurnoAReprogramar = this.$store.state.TurnoAReprogramar;

            // Se envia peticion a backend para recuperar turno
            Axios.post(appsettings.Backend + this.UrlsTurnos.Dame, this.TurnoAReprogramar)
            .then(response => {
                // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                if (response.data.ErrorOperacion == 0) {
                    if (response.data.ErrorInesperado == 0) {
                        this.Turno = response.data.Data;
                    }
                    else{
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                        this.MostrarPopUp = true;
                    }
                }
                else{
                    this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                    this.MostrarPopUp = true;
                }
            })
            .catch(error => {
                // Se actualiza info de popup
                this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                this.MostrarPopUp = true;
                console.log(error);
            });

        },
        mounted(){
            document.getElementById("buscarUsuarioSolicitante").value = this.TurnoAReprogramar.usuarioSolicitante
            document.getElementById("buscarUsuarioEjecutante").value = this.TurnoAReprogramar.usuarioEjecutante
            document.getElementById("buscarExperiencia").value = this.TurnoAReprogramar.experiencia
        },
        methods: {
            Seleccion(Horario){
                if(this.HorarioSeleccionado){
                    if (this.HorarioSeleccionado!=Horario) {
                        const inputAnterior = document.getElementById(this.HorarioSeleccionado);
                        const inputNuevo = document.getElementById(Horario);
                        inputAnterior.className = 'button-horario';
                        inputNuevo.className = 'button-horario-selected';
                        this.HorarioSeleccionado = Horario; 
                    }
                }else {
                    const inputNuevo = document.getElementById(Horario);
                    inputNuevo.className = 'button-horario-selected';
                    this.HorarioSeleccionado = Horario; 
                }
            },
            Validar(){
                const inputFecha = document.getElementById("fechaTurno");
                
                if(!(inputFecha.value === '')){

                    inputFecha.classList.remove('invalid');
                    inputFecha.classList.add('valid');
                }
                else{
                    inputFecha.classList.remove('valid');
                    inputFecha.classList.add('invalid');
                }
            },
            VerHorariosDisponibles(){
                const inputFecha = document.getElementById("fechaTurno");
                const inputExperiencia = document.getElementById("buscarExperiencia");
                this.HorariosDisponibles = [];
                this.FechaTurno = inputFecha.value;
                console.log(this.FechaTurno)
                if(inputFecha && !(inputFecha.value==='')){

                    if(inputExperiencia && !(inputExperiencia.value==='')){
                        const payload={
                            fechaSolicitada: document.getElementById("fechaTurno").value,
                            ...this.Turno
                        }

                        // Se envia peticion a backend
                        Axios.post(appsettings.Backend + this.UrlsTurnos.ObtenerHorariosDisponibles, payload)
                        .then(response => {
                            // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                            if (response.data.ErrorOperacion == 0) {
                                if (response.data.ErrorInesperado == 0) {
                                    const auxiliar = response.data.Data;

                                    if(auxiliar && auxiliar.length>0){
                                        auxiliar.forEach(horario =>{
                                            this.HorariosDisponibles.push(horario.inicio + '-' + horario.fin)
                                        })
                                    }
                                    else{
                                        this.ActualizarPopUp("Solicitud de Turnos","No hay turnos disponibles para la fecha seleccionada",true);
                                        this.MostrarPopUp = true;
                                    }
                                }
                                else{
                                    // Se actualiza info de popup
                                    this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                                    this.MostrarPopUp = true;
                                }
                            }
                            else{
                                this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                                this.MostrarPopUp = true;
                            }
                        })
                        .catch(error => {
                            // Se actualiza info de popup
                            this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                            this.MostrarPopUp = true;
                            console.log(error);
                        });
                    }
                    else{
                        inputExperiencia.classList.add('invalid');
                    }
                }
                else{
                    inputFecha.classList.add('invalid');
                }

            },
            ActualizarPopUp(Titulo,Mensaje,Cerrar){
                // Se carga informacion al popup
                this.InfoPopUp = {
                    name: "PopUpComponent",
                    props: {
                        CierreHabilitado: Cerrar,
                        Titulo: Titulo,
                        Mensaje: Mensaje
                    }
                };
                this.$store.commit("setPropertiesRoute", this.InfoPopUp);
            },
            CerrarPopUp(){
                // Se cita el popup de pantalla cuando el cliente lo cierra
                this.MostrarPopUp = false;
            },
            ReprogramarTurno(){
                // Se envia peticion al backend
                const payload = {
                    ...this.Turno,
                    fechaHoraInicioReprogramada: document.getElementById("fechaTurno").value +' '+ this.HorarioSeleccionado.split("-")[0]
                }

                Axios.post(appsettings.Backend + this.UrlsTurnos.Reprogramar, payload)
                .then(response => {
                    // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {

                            this.ActualizarPopUp("Solicitud de Turnos",response.data.Data,true);
                            this.MostrarPopUp = true;
                        }
                        else{
                            // Se actualiza info de popup
                            this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                            this.MostrarPopUp = true;
                        }
                    }
                    else{
                        this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                        this.MostrarPopUp = true;
                    }
                    this.Limpiar()
                })
                .catch(error => {
                    // Se actualiza info de popup
                    this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                    this.MostrarPopUp = true;
                    console.log(error);
                });
                
            },
            Limpiar(){
                const fecha = document.getElementById("fechaTurno");

                fecha.value = '';
                fecha.classList.remove('valid');
                fecha.classList.remove('invalid');

                this.HorariosDisponibles = null;
                this.HorarioSeleccionado = null;


            }
        },
        components:{
            PopUpComponent
        }
        
        // components:{
        //     PopUpComponent
        // }
    };
</script>

<style scoped>
.tablestyle {
    font-size: 12px;  
    text-align:center; 
    width: auto; 
    vertical-align: middle;
}

.autocomplete-list {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.autocomplete-list ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style: none;
}

.autocomplete-list ul li {
  padding: 5px;
  cursor: pointer;
}

.autocomplete-list ul li:hover {
  background-color: #f1f1f1;
}
.custom-tooltip {
  position: relative;
}
.custom-tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -30px;
  transform: translateX(-50%);
}
.custom-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -6px;
  border-top: 6px solid #333;
  border-bottom: none;
}
.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
  visibility: visible;
  opacity: 1;
}

.button-horario {
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.button-horario-selected {
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #3a86ff;
  color: #fff;
  cursor: pointer;
}

.scroll {
  min-height: 190px;
  max-height: 223px; 
  overflow-y: auto;
}

.input {
  border: 1px solid;
  border-color: gainsboro;
}

.input.invalid {
  border-color: red;
}

.input.valid {
  border-color: green;
}

</style>