<template>
      <footer class="footer">
        <div class="row d-flex justify-content-between text-center">
          <div class="col-lg-4">
            <img class="my-2" src="../../../public/assets/img/logoUNT.jpeg" alt="Imagen 1" width="102px" height="128px">
          </div>
          <div class="col-lg-4">
            <img class="my-2" src="../../../public/assets/img/logofacet.jpeg" alt="Imagen 2" width="299px" height="128px">
          </div>
          <div class="col-lg-4">
            <img class="my-2" src="../../../public/assets/img/logoConfedi.jpeg" alt="Imagen 3" width="128px" height="128px">
          </div>
        </div>
        <p class="text-center mt-2">2023 LabRem - Facet UNT. Todos los derechos reservados.</p>
      </footer>
  </template>
  
  <style>
  .footer {
    background-color: #f8f8f8;
    text-align: center;
  }
  </style>
  