<template>

    <div class="container-fluid">
        <div data-v-4df87c9c="" class="p-5">
            <div data-v-4df87c9c="" class="col-lg-8 text-center">
                <h1 data-v-4df87c9c="" class="h4 text-gray-900 mb-4">Para continuar debe completar sus datos</h1>
                <span data-v-4df87c9c="" class="close-button">X</span>
            </div>
            
            <form data-v-4df87c9c="" class="user">
                <div data-v-4df87c9c="" class="form-group">
                    <div data-v-4df87c9c="" class="col-lg-8">
                        <label data-v-4df87c9c="" class="col-lg-12">Contraseña</label>
                        <input data-v-4df87c9c="" class="form-control form-control-user" id="contrasena" type="text">
                    </div>
                </div>
                <div data-v-4df87c9c="" class="form-group">
                    <div data-v-4df87c9c="" class="col-lg-8">
                        <label data-v-4df87c9c="" class="col-lg-12">DNI</label>
                        <input data-v-4df87c9c="" class="form-control form-control-user" id="dni" type="text" :value="Usuario['dni']">
                    </div>
                </div>
                <div data-v-4df87c9c="" class="form-group">
                    <div data-v-4df87c9c="" class="col-lg-8">
                        <label data-v-4df87c9c="" class="col-lg-12">Nombre</label>
                        <input data-v-4df87c9c="" class="form-control form-control-user" id="nombre" type="text" :value="Usuario['nombre']">
                    </div>
                </div>
                <div data-v-4df87c9c="" class="form-group">
                    <div data-v-4df87c9c="" class="col-lg-8">
                        <label data-v-4df87c9c="" class="col-lg-12">Apellido</label>
                        <input data-v-4df87c9c="" class="form-control form-control-user" id="apellido" type="text" :value="Usuario['apellido']">
                    </div>
                </div>
                <div data-v-4df87c9c="" class="form-group">
                    <div data-v-4df87c9c="" class="col-lg-8">
                        <label data-v-4df87c9c="" class="col-lg-12">Correo Electronico</label>
                        <input data-v-4df87c9c="" class="form-control form-control-user" id="correoElectronico" type="text" :value="Usuario['correoElectronico']">
                    </div>
                </div>
                <div data-v-4df87c9c="" class="form-group">
                    <div data-v-4df87c9c="" class="col-lg-8">
                        <label data-v-4df87c9c="" class="col-lg-12">Universidad</label>
                        <input data-v-4df87c9c="" class="form-control form-control-user" id="universidad" type="text" :value="Usuario['universidad']" :disabled="true">
                    </div>
                </div>
                <div data-v-4df87c9c="" class="col-lg-8">
                    <hr data-v-4df87c9c="">
                </div>
                <div data-v-4df87c9c="" class="col-lg-8 center">
                    <a data-v-4df87c9c="" class="col-lg-2 content btn btn-primary btn-user btn-block" v-on:click="Aceptar"> Aceptar </a>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import Crypt from 'crypto-js';
    import { appsettings } from '../../appsettings';
    import Axios from 'axios';
    import { getDataStorage } from '../utils/StorageUtils';
    export default {
        data() {
            return {
                Usuario: {},
                Urls: {}
            }
        },
        created(){
            this.Usuario = JSON.parse(getDataStorage("usuario"))

            appsettings.UrlsBackend.forEach(modulo => {
                if ("Usuarios" in modulo) {
                    this.Urls = modulo["Usuarios"];
                }
            });
        },
        methods:{
            Aceptar(){
                const inputPassword = document.getElementById("contrasena").value;
                const contrasenaEncriptada = Crypt.AES.encrypt(inputPassword, appsettings.KeyEncript).toString();
                const inputDni = document.getElementById("dni");
                const inputNombre = document.getElementById("nombre");
                const inputApellido = document.getElementById("apellido");
                const inputU = document.getElementById("universidad");
                const inputCE = document.getElementById("correoElectronico");

                console.log(inputU.value)

                if(inputDni.value!='' && inputApellido.value!='' && inputCE.value!='' && inputU.value!='' && inputNombre.value!=''){

                    const payload={
                        idUsuario: this.Usuario.idUsuario,
                        idUniversidad: this.Usuario.idUniversidad,
                        dni: inputDni.value,
                        nombre: inputNombre.value,
                        apellido: inputApellido.value,
                        correoElectronico: inputCE.value,
                        universidad: inputU.value 
                    }
                    // Se envia peticion a backend para el alta
                    Axios.post(appsettings.Backend + this.Urls.Modificar,payload)
                    .then(response => {
                        // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                        if (response.data.ErrorOperacion == 0) {
                            if (response.data.ErrorInesperado == 0) {
                                sessionStorage.setItem('usuario',JSON.stringify(payload))
                                
                                // Se modifica la contrasena
                                Axios.post(appsettings.Backend + this.Urls.ModificarContrasena,
                                {
                                    ...payload,
                                    pass: contrasenaEncriptada
                                })
                                .then(response => {
                                    if (response.data.ErrorOperacion == 0 && response.data.ErrorInesperado == 0) {
                                        this.$router.push({name:'main'})
                                    }
                                    else {
                                        this.$router.push({name:'inicio'});
                                    }
                                })
                                .catch(error =>{
                                    console.log(error);
                                    this.$router.push({name:'inicio'});
                                })
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$router.push({name:'inicio'});
                    });
                }
            }
        }
    }

</script>