import Cookies from 'js-cookie'

function getDataStorage(key) {
    
    let data = Cookies.get(key);

    if(!data){
        data = sessionStorage.getItem(key);
    }

    if(!data){
        data = localStorage.getItem(key);
    }

    if(!data){
        data = null;
    }

    return data;

}

export { getDataStorage };