<template>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-12">
                <form class="user">
                    <div class="row">
                        <div class="col-lg-1"></div>
                            <div class="col-lg-5">
                                <div class="form-group">
                                    <label class="col-lg-12">Usuario Solicitante</label>
                                    <input class="form-control form-control-user input" type="text" id="buscarUsuarioSolicitante" :value="UsuarioSolicitante['apellido'] + ' ' + UsuarioSolicitante['nombre']" :disabled="true">
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="form-group">
                                    <label class="col-lg-12">Usuario Ejecutante</label>
                                    <input v-on:input="BuscarUsuario('buscarUsuarioEjecutante')" class="form-control form-control-user input" type="text" id="buscarUsuarioEjecutante">
                                    <div v-if="MostrarListaB" class="autocomplete-list">
                                        <ul>
                                            <li v-for="Usuario in Usuarios" :key="Usuario" v-on:click="CompletarUsuario(Usuario,'buscarUsuarioEjecutante')">{{ Usuario['apellido'] + ' ' + Usuario['nombre'] }}</li>
                                            <li  v-if="MostrarMensajeB">No hay coincidencias</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1"></div>
                    </div>
 
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-5">
                            <div class="form-group">
                                <label class="col-lg-12">Experiencia</label>
                                <input v-on:input="BuscarExperiencia()" class="form-control form-control-user input" type="text" id="buscarExperiencia">
                                <div v-if="MostrarListaC" class="autocomplete-list">
                                    <ul>
                                        <li v-for="Experiencia in Experiencias" :key="Experiencia" v-on:click="CompletarExperiencia(Experiencia)">{{ Experiencia['nombre'] }}</li>
                                        <li  v-if="MostrarMensajeC">No hay coincidencias</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label class="col-lg-12">Fecha</label>
                                <input class="form-control form-control-user input" type="date" id="fechaTurno" v-on:input="Validar" :min="FechaMinima">
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <label class="col-lg-12" style="visibility: hidden;">Ver Turnos</label>
                            <div class="col-lg-12 text-center">
                                <a v-on:click="VerHorariosDisponibles" class="btn btn-primary btn-user btn-block">
                                    Ver Turnos
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-10">
                            <hr>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-10">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3 contenedor-flexbox">
                                    <h6 class="m-0 font-weight-bold text-primary">Seleccionar turno</h6>
                                </div>
                                <div class="card-body contenedor-flexbox scroll" style="width: 100%;">
                                    <div>
                                        <input v-for="Horario in HorariosDisponibles" :key="Horario" type="button" :id="Horario" class="button-horario" :value="Horario" v-on:click="Seleccion(Horario)">
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-5"></div>
                        <div class="col-lg-2">
                            <a class="btn btn-primary btn-user btn-block" v-on:click="SolicitarTurno">
                                Aceptar
                            </a>
                        </div>
                        <div class="col-lg-5"></div>
                    </div>
                </form>
            </div>
        </div> 

    </div>

    <PopUpComponent v-if="MostrarPopUp" @CerrarPopUp="CerrarPopUp()"></PopUpComponent>

</template>

<script>

    // import PopUpComponent from './commons/PopUpComponent.vue';
    import PopUpComponent from './commons/PopUpComponent.vue';
    import { appsettings } from '../../appsettings';
    import Axios from 'axios';
    import { getDataStorage } from '../utils/StorageUtils';

    export default {
        data() {
            return {
                    HorarioSeleccionado: null,
                    HorariosDisponibles: [],
                    UrlsUsuarios: [],
                    UrlsExperiencias: [],
                    UrlsTurnos:[],
                    FechaTurno: null,
                    Usuarios: [],
                    UsuarioEjecutante: null,
                    UsuarioSolicitante: null,
                    Experiencias: [],
                    Experiencia: null,
                    MostrarPopUp: false,
                    MostrarMensajeA: false,
                    MostrarMensajeB: false, 
                    MostrarMensajeC: false, 
                    MostrarListaA: false,
                    MostrarListaB: false, 
                    MostrarListaC: false,   
                    FechaMinima: '' 
            };
        },
        mounted(){
            this.FechaMinima = new Date(new Date().getTime() + -3 * 60 * 60 * 1000).toISOString().split('T')[0];
        },
        created(){
            //Se traen urls del modulo a gestionar
            appsettings.UrlsBackend.forEach(modulo => {
                if ("Usuarios" in modulo) {
                    this.UrlsUsuarios = modulo["Usuarios"];
                }
                if ("Experiencias" in modulo) {
                    this.UrlsExperiencias = modulo["Experiencias"];
                }
                if ("Turnos" in modulo) {
                    this.UrlsTurnos = modulo["Turnos"];
                }
            });

            this.UsuarioSolicitante = JSON.parse(getDataStorage("usuario"));
        },
        methods: {
            BuscarUsuario(idInput){

                const input = document.getElementById(idInput);
                input.classList.remove('valid')
                input.classList.add('invalid')

                // Se realiza la peticion de busqueda de usuarios a partir de tres caracteres
                let Cadena = input.value;

                if(idInput=='buscarUsuarioSolicitante'){
                    this.UsuarioSolicitante = null
                }
                else if(idInput=='buscarUsuarioEjecutante'){
                    this.UsuarioEjecutante = null
                }

                if (Cadena == 0) {
                    if(idInput=='buscarUsuarioSolicitante'){
                        this.MostrarListaA = false;
                        this.MostrarMensajeA = false;
                        this.UsuarioSolicitante = null;
                    }
                    else if(idInput=='buscarUsuarioEjecutante'){
                        this.MostrarListaB = false;
                        this.MostrarMensajeB = false;
                        this.UsuarioEjecutante = null;
                    }
                    this.Usuarios = null;
                }
                if (Cadena.length >= 3) {

                    // Se arma payload de la solicitud http
                    const payload = {
                        "cadena": Cadena
                    };

                    // Se envia peticion a backend
                    Axios.post(appsettings.Backend + this.UrlsUsuarios.Buscar, payload)
                    .then(response => {
                        // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                        if (response.data.ErrorOperacion == 0) {
                            if (response.data.ErrorInesperado == 0) {
                                this.Usuarios = response.data.Data;

                                if(idInput=='buscarUsuarioSolicitante'){
                                    if(!this.Usuarios){
                                        this.MostrarMensajeA = true;
                                    }
                                    else{
                                        this.MostrarMensajeA = false;
                                    }
                                    console.log('estoy aqui')
                                    this.MostrarListaA = true;
                                }
                                else if(idInput=='buscarUsuarioEjecutante'){
                                    if(!this.Usuarios){
                                        this.MostrarMensajeB = true;
                                    }
                                    else{
                                        this.MostrarMensajeB = false;
                                    }
                                    this.MostrarListaB = true;
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            },
            CompletarUsuario(Usuario,idInput){
                const input = document.getElementById(idInput);
                input.value = Usuario['apellido'] + ' ' + Usuario['nombre']
                input.classList.remove('invalid');
                input.classList.add('valid');

                if(idInput=='buscarUsuarioSolicitante'){
                    this.UsuarioSolicitante = Usuario;
                    this.MostrarListaA = false
                }
                else if(idInput=='buscarUsuarioEjecutante'){
                    this.UsuarioEjecutante = Usuario;
                    this.MostrarListaB = false;
                }
            },
            BuscarExperiencia(){

                const input = document.getElementById('buscarExperiencia');
                input.classList.remove('valid')
                input.classList.add('invalid')

                // Se realiza la peticion de busqueda de usuarios a partir de tres caracteres
                let Cadena = input.value;

                if (Cadena == 0) {
                    this.MostrarListaC = false;
                    this.MostrarMensajeC = false;
                    this.Experiencias = null;
                }
                if (Cadena.length >= 3) {
                    // Se arma payload de la solicitud http
                    const payload = {
                        "cadena": Cadena
                    };

                    // Se envia peticion a backend
                    Axios.post(appsettings.Backend + this.UrlsExperiencias.Buscar, payload)
                    .then(response => {
                        // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                        if (response.data.ErrorOperacion == 0) {
                            if (response.data.ErrorInesperado == 0) {
                                this.Experiencias = response.data.Data;
                                if(!this.Experiencias){
                                    this.MostrarMensajeC = true;
                                }
                                else{
                                    this.MostrarMensajeC = false;
                                }
                                this.MostrarListaC = true;
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            },
            CompletarExperiencia(Experiencia){
                const input = document.getElementById('buscarExperiencia');
                input.value = Experiencia['nombre']
                input.classList.remove('invalid');
                input.classList.add('valid');
                this.Experiencia = Experiencia;
                this.MostrarListaC = false
            },
            Seleccion(Horario){
                if(this.HorarioSeleccionado){
                    if (this.HorarioSeleccionado!=Horario) {
                        const inputAnterior = document.getElementById(this.HorarioSeleccionado);
                        const inputNuevo = document.getElementById(Horario);
                        inputAnterior.className = 'button-horario';
                        inputNuevo.className = 'button-horario-selected';
                        this.HorarioSeleccionado = Horario; 
                    }
                }else {
                    const inputNuevo = document.getElementById(Horario);
                    inputNuevo.className = 'button-horario-selected';
                    this.HorarioSeleccionado = Horario; 
                }
            },
            Validar(){
                const inputFecha = document.getElementById("fechaTurno");
                
                if(!(inputFecha.value === '')){

                    inputFecha.classList.remove('invalid');
                    inputFecha.classList.add('valid');
                }
                else{
                    inputFecha.classList.remove('valid');
                    inputFecha.classList.add('invalid');
                }
            },
            VerHorariosDisponibles(){
                const inputFecha = document.getElementById("fechaTurno");
                const inputExperiencia = document.getElementById("buscarExperiencia");
                this.HorariosDisponibles = [];
                this.FechaTurno = inputFecha.value;

                if(inputFecha && !(inputFecha.value==='')){

                    if(inputExperiencia && !(inputExperiencia.value==='')){
                        const payload={
                            fechaSolicitada: document.getElementById("fechaTurno").value,
                            ...this.Experiencia
                        }

                        // Se envia peticion a backend
                        Axios.post(appsettings.Backend + this.UrlsTurnos.ObtenerHorariosDisponibles, payload)
                        .then(response => {
                            // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                            if (response.data.ErrorOperacion == 0) {
                                if (response.data.ErrorInesperado == 0) {
                                    const auxiliar = response.data.Data;

                                    if(auxiliar && auxiliar.length>0){
                                        auxiliar.forEach(horario =>{
                                            this.HorariosDisponibles.push(horario.inicio + '-' + horario.fin)
                                        })
                                    }
                                    else{
                                        this.ActualizarPopUp("Solicitud de Turnos","No hay turnos disponibles para la fecha seleccionada",true);
                                        this.MostrarPopUp = true;
                                    }
                                }
                                else{
                                    // Se actualiza info de popup
                                    this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                                    this.MostrarPopUp = true;
                                }
                            }
                            else{
                                this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                                this.MostrarPopUp = true;
                            }
                        })
                        .catch(error => {
                            // Se actualiza info de popup
                            this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                            this.MostrarPopUp = true;
                            console.log(error);
                        });
                    }
                    else{
                        inputExperiencia.classList.add('invalid');
                    }
                }
                else{
                    inputFecha.classList.add('invalid');
                }

            },
            ActualizarPopUp(Titulo,Mensaje,Cerrar){
                // Se carga informacion al popup
                this.InfoPopUp = {
                    name: "PopUpComponent",
                    props: {
                        CierreHabilitado: Cerrar,
                        Titulo: Titulo,
                        Mensaje: Mensaje
                    }
                };
                this.$store.commit("setPropertiesRoute", this.InfoPopUp);
            },
            CerrarPopUp(){
                // Se cita el popup de pantalla cuando el cliente lo cierra
                this.MostrarPopUp = false;
            },
            SolicitarTurno(){
                // Se envia peticion al backend
                const [horaInicioIntervalo, AMPM] = this.HorarioSeleccionado.split("-")[0].split(' ');
                const [hora, minutos] = horaInicioIntervalo.split(':');
                
                let horaTurno;

                if(parseInt(hora) != 12 && AMPM == "PM"){
                    horaTurno = parseInt(hora) + 12
                }
                else{
                    horaTurno = hora
                }

                const payload = {
                    usuarioE: this.UsuarioEjecutante,
                    usuarioS: this.UsuarioSolicitante,
                    experiencia: this.Experiencia,
                    fechaTurno: this.FechaTurno,
                    horaTurno: horaTurno + ":" + minutos + " " + AMPM
                }


                Axios.post(appsettings.Backend + this.UrlsTurnos.Solicitar, payload)
                .then(response => {
                    // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {

                            this.ActualizarPopUp("Solicitud de Turnos",response.data.Data,true);
                            this.MostrarPopUp = true;
                        }
                        else{
                            // Se actualiza info de popup
                            this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                            this.MostrarPopUp = true;
                        }
                    }
                    else{
                        this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                        this.MostrarPopUp = true;
                    }
                    this.Limpiar()
                })
                .catch(error => {
                    // Se actualiza info de popup
                    this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                    this.MostrarPopUp = true;
                    console.log(error);
                });
                
            },
            Limpiar(){
                const usuarioE = document.getElementById("buscarUsuarioEjecutante");
                const experiencia = document.getElementById("buscarExperiencia");
                const fecha = document.getElementById("fechaTurno");

                usuarioE.value = '';
                usuarioE.classList.remove('valid');
                usuarioE.classList.remove('invalid');
                experiencia.value = '';
                experiencia.classList.remove('valid');
                experiencia.classList.remove('invalid');
                fecha.value = '';
                fecha.classList.remove('valid');
                fecha.classList.remove('invalid');

                this.Experiencia = null;
                this.UsuarioEjecutante = null;
                this.FechaTurno = null;
                this.HorariosDisponibles = null;
                this.HorarioSeleccionado = null;


            }
        },
        components:{
            PopUpComponent
        }
        
        // components:{
        //     PopUpComponent
        // }
    };
</script>

<style scoped>
.tablestyle {
    font-size: 12px;  
    text-align:center; 
    width: auto; 
    vertical-align: middle;
}

.autocomplete-list {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.autocomplete-list ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style: none;
}

.autocomplete-list ul li {
  padding: 5px;
  cursor: pointer;
}

.autocomplete-list ul li:hover {
  background-color: #f1f1f1;
}
.custom-tooltip {
  position: relative;
}
.custom-tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -30px;
  transform: translateX(-50%);
}
.custom-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -6px;
  border-top: 6px solid #333;
  border-bottom: none;
}
.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
  visibility: visible;
  opacity: 1;
}

.button-horario {
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.button-horario-selected {
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #3a86ff;
  color: #fff;
  cursor: pointer;
}

.scroll {
  min-height: 190px;
  max-height: 223px; 
  overflow-y: auto;
}

.input {
  border: 1px solid;
  border-color: gainsboro;
}

.input.invalid {
  border-color: red;
}

.input.valid {
  border-color: green;
}

</style>