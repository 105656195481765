<!-- Componente que se usa para renderizar mensajes en pantalla, se puede renderizar tambien un componnte dentro -->

<template>
    <div class="popup-overlay">
        <div class="popup-container">
            <div class="col-lg-12">
                
                <span v-if="this.CierreHabilitado" class="close-button" @click="CerrarPopup" >x</span>
                
                <div class="p-5">
                    <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-4">{{ Titulo }}</h1>
                    </div>
                    <hr v-if="Titulo">
                    <div class="text-center">
                        <h1 class="h6 text-gray-900 mb-4">{{ Mensaje }}</h1>
                    </div>
                    <!-- Permite insertar otros componentes dentro del popup -->
                    <slot></slot>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

    export default {
        data(){
            return{
                CierreHabilitado: false,
                Titulo: null,
                Mensaje: null,
                Props: {}
            }
        },
        created(){
            // Se traen propiedades del state
            this.Props = this.$store.state.PopUpComponent;

            this.CierreHabilitado = this.Props? this.Props.CierreHabilitado : false;
            this.Titulo = this.Props? this.Props.Titulo : null;
            this.Mensaje = this.Props? this.Props.Mensaje : null;

        },
        methods: {
            // Cuando el popup se cierra avisa al padre quien lo llama
            CerrarPopup() {
                this.$emit('cerrarPopUp');
            }
        }
    };
</script>
  
<style scoped>
    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
    .popup-container {
        position:absolute;
        z-index: 9999;
        background-color: #fff;
        max-width: 800px;
        padding: 1px;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        font-size: 20px;
    }
</style>