export const appsettings = {
    Frontend: 'https://labrem.facet.unt.edu.ar:8080',
    Backend: 'https://labrem.facet.unt.edu.ar:3000',
    UrlValidacionToken: '/lti/ValidarToken',
    UrlValidacionCredenciales: '/auth/LogIn',
    UrlsBackend: [
        {
            Usuarios: {
                Crear: '/usuarios/CrearUsuario',
                Dame: '/usuarios/DameUsuario',
                Buscar: '/usuarios/BuscarUsuarios',
                Listar: '/usuarios/ListarUsuarios',
                Modificar: '/usuarios/ModificarUsuario',
                DarDeAlta: '/usuarios/DarDeAltaUsuario',
                DarDeBaja: '/usuarios/DarDeBajaUsuario',
                Borrar: '/usuarios/BorrarUsuario',
                ModificarContrasena: '/usuarios/ModificarContrasena',
                DameRolDeUsuario: '/usuarios/DameRolDeUsuario',
                AgregarRolAUsuario: '/usuarios/AgregarRolAUsuario',
                ListarRolesDeUsuario: '/usuarios/ListarRolesDeUsuario',
                ListarPermisosDeUsuario: '/usuarios/ListarPermisosDeUsuario',
                DarDeAltaRolAUsuario: '/usuarios/DarDeAltaRolAUsuario',
                DarDeBajaRolAUsuario: '/usuarios/DarDeBajaRolAUsuario',
                QuitarRolAUsuario: '/usuarios/QuitarRolAUsuario'
            }
        },
        {
            Laboratorios: {
                Crear: '/laboratorios/CrearLaboratorio',
                Dame: '/laboratorios/DameLaboratorio',
                Buscar: '/laboratorios/BuscarLaboratorios',
                ListarPaginado: '/laboratorios/ListarLaboratoriosPaginado',
                Listar: '/laboratorios/ListarLaboratorios',
                Modificar: '/laboratorios/ModificarLaboratorio',
                DarDeAlta: '/laboratorios/DarDeAltaLaboratorio',
                DarDeBaja: '/laboratorios/DarDeBajaLaboratorio',
                Borrar: '/laboratorios/BorrarLaboratorio',
                ModificarDisponibilidad: '/laboratorios/ModificarDisponibilidad'
            }
        },
        {
            Experiencias:{
                Crear: '/experiencias/CrearExperiencia',
                Dame: '/experiencias/DameExperiencia',
                Buscar: '/experiencias/BuscarExperiencias',
                ListarPaginado: '/experiencias/ListarExperienciasPaginado',
                Listar: '/experiencias/ListarExperiencias',
                Modificar: '/experiencias/ModificarExperiencia',
                DarDeAlta: '/experiencias/DarDeAltaExperiencia',
                DarDeBaja: '/experiencias/DarDeBajaExperiencia',
                Borrar: '/experiencias/BorrarExperiencia',
                AgregarHardware: '/experiencias/AgregarHardware',
                QuitarHardware: '/experiencias/QuitarHardware',

            }
        },
        {
            Hardwares:{
                Crear: '/hardwares/CrearHardware',
                Dame: '/hardwares/DameHardware',
                Buscar: '/hardwares/BuscarHardwares',
                ListarPaginado: '/hardwares/ListarHardwaresPaginado',
                Listar: '/hardwares/ListarHardwares',
                Modificar: '/hardwares/ModificarHardware',
                DarDeAlta: '/hardwares/DarDeAltaHardware',
                DarDeBaja: '/hardwares/DarDeBajaHardware',
                Borrar: '/hardwares/BorrarHardware',
            }
        },
        {
            Plataformas:{
                Crear: '/lti/RegistrarPlataforma',
                Listar:'/lti/ListarPlataformas',
                Modificar: '/lti/ModificarPlataforma',
                Borrar: '/lti/BorrarPlataforma',   
            }
        },
        {
            Roles:{
                Crear: '/permisos/CrearRol',
                Dame: '/permisos/DameRol',
                Buscar: '/permisos/BuscarRoles',
                Listar: '/permisos/ListarRoles',
                ListarPaginado: '/permisos/ListarRolesPaginado',
                Modificar: '/permisos/ModificarRol',
                DarDeAlta: '/permisos/DarDeAltaRol',
                DarDeBaja: '/permisos/DarDeBajaRol',
                Borrar: '/permisos/BorrarRol',
                DamePermisoDeUsuario: '/permisos/DamePermisoDeUsuario',
                AgregarPermisoARol: '/permisos/AgregarPermisoARol',
                ListarPermisosDeRol: '/permisos/ListarPermisosDeRol',
                DarDeAltaPermisoEnRol: '/permisos/DarDeAltaPermisoEnRol',
                DarDeBajaPermisoEnRol: '/permisos/DarDeBajaPermisoEnRol',
                QuitarPermisoARol: '/permisos/QuitarPermisoARol'
            }
        },
        {
            Permisos:{
                Crear: '/permisos/CrearPermiso',
                Dame: '/permisos/DamePermiso',
                Buscar: '/permisos/BuscarPermisos',
                ListarPaginado: '/permisos/ListarPermisosPaginado',
                Listar: '/permisos/ListarPermisos',
                Modificar: '/permisos/ModificarPermiso',
                DarDeAlta: '/permisos/DarDeAltaPermiso',
                DarDeBaja: '/permisos/DarDeBajaPermiso',
                Borrar: '/permisos/BorrarPermiso',
            }
        },
        {
            Universidades:{
                Crear: '/universidades/CrearUniversidad',
                Dame: '/universidades/DameUniversidad',
                Buscar: '/universidades/BuscarUniversidades',
                ListarPaginado: '/universidades/ListarUniversidadesPaginado',
                Listar: '/universidades/ListarUniversidades',
                Modificar: '/universidades/ModificarUniversidad',
                DarDeAlta: '/universidades/DarDeAltaUniversidad',
                DarDeBaja: '/universidades/DarDeBajaUniversidad',
                Borrar: '/universidades/BorrarUniversidad',
                CrearPlataforma: '/universidades/RegistrarPlataforma',
                DamePlataforma:'/universidades/DamePlataforma',
                ModificarPlataforma: '/universidades/ModificarPlataforma',
                BorrarPlataforma: '/universidades/BorrarPlataforma',  
            }
        },
        {
            Turnos:{
                Solicitar: '/turnos/SolicitarTurno',
                Dame: '/turnos/DameTurno',
                Buscar: '/turnos/BuscarTurnosPaginado',
                Cancelar: '/turnos/CancelarTurno',
                Reprogramar: '/turnos/ReprogramarTurno',
                Borrar: '/turnos/BorrarTurno',
                ObtenerHorariosDisponibles: '/turnos/ObtenerHorariosDisponibles',
                ValidarTurnoEnExperiencia: '/turnos/ValidarTurnoEnExperiencia'
            }
        },
        {
            Calendario:{
                Crear: '/calendario/CrearDiaNoLaboral',
                Modificar: '/calendario/ModificarDiaNoLaboral',
                Dame: '/calendario/DameDiaNoLaboral',
                Buscar: '/calendario/BuscarDiasNoLaborales',
                Listar: '/calendario/ListarDiasNoLaboralesPaginado',
                Borrar: '/calendario/BorrarDiaNoLaboral',
            }
        }
    ],
    KeyEncript: 'labrem123456789'
  };