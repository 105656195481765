<!-- Componente de inicio, utilizado para la autenticacion de usuarios -->
<template>
    <div class="container-fluid">
        <div class="row d-flex justify-content-center align-items-center mt-4">
            <div class="col-md-8 d-flex justify-content-center align-items-center">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">LABORATORIOS REMOTOS UNT</h1>
                            </div>
                            <form class="user">
                                <div class="input-group">
                                    <input type="email" class="form-control"
                                            id="usuario" aria-describedby="emailHelp"
                                            placeholder="Correo electrónico">
                                            <div class="input-group-text">
                                                <span class="fas fa-user"></span>
                                            </div>
                                </div>
                                <div class="input-group">
                                        <input type="password" class="form-control"
                                                id="contrasena" placeholder="Contraseña">
                                        <div class="input-group-text">
                                            <span class="fas fa-lock"></span>
                                        </div>
                                </div>
                                    <div class="checkbox-container my-2">
                                        <label for="recuerdame" class="checkbox-label">
                                            <input type="checkbox" id="recuerdame" name="recuerdame" class="checkbox-input">
                                            <span class="checkbox-custom"></span>
                                            <span class="checkbox-text">Recuérdame</span>
                                        </label>
                                    </div>
                                    <a v-on:click="LogIn" class="btn btn-primary w-100">
                                        <strong>Iniciar Sesión</strong>
                                    </a>
                            </form>
                            <p class="card-link text-center mt-3">
                                <a href="#">¿Olvidó su contraseña?</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AppFooter/>
    <PopUpComponent v-if="MostrarPopUp" @CerrarPopUp="CerrarPopUp()"></PopUpComponent> 
</template>
  
<script>

    import Axios from 'axios';
    import Crypt from 'crypto-js';
    import Cookies from 'js-cookie';
    import PopUpComponent from './commons/PopUpComponent.vue';
    import AppFooter from './commons/AppFooter.vue';
    import { appsettings } from '../../appsettings';
    import { getDataStorage } from '@/utils/StorageUtils';
    export default {
        data() {
            return {
                MostrarPopUp: false
            };
        },
        created(){
 
            // const params = this.$route.params.r
            // console.log(this.$route.params)
           

            const params =this.$route.redirectedFrom.params
            console.log(params)

            // Si hay datos en url se validan contra el back para determinar si es una redireccion valida
            if(params && params.data){

                let data = params.data;

                Axios.post(appsettings.Backend + '/retrieve',{uuid:data})
                .then(response => {
                    console.log(response)
                    sessionStorage.setItem('token',JSON.stringify(response.data.token));
                    sessionStorage.setItem('usuario',JSON.stringify(response.data.usuario))
                    sessionStorage.setItem('listapermisos',JSON.stringify(response.data.permisos))

                    Axios.post(appsettings.Backend + appsettings.UrlValidacionToken,{ltik: response.data.token})
                    .then(() => {
                        //Se evaluan los permisos para hacer filtros en pantalla
                        this.EvaluarPermisos(getDataStorage('listapermisos'));

                        // En caso de que la opcion validado se encuenre en tru se redirige al inicio caso contrario a una ventana para completar informacion faltante
                        if(response.data.validado){
                            this.$router.push({name: 'main'});
                        }
                        else{
                            this.$router.push({name: 'completarinfo'});
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        let payload;
                        if(error.response){
    
                            payload = {
                                name: 'PageErrorComponent',
                                props: {
                                    Codigo: error.response.status,
                                    Mensaje: error.response.statusText,
                                    Descripcion: ''
                                }
                            }
                            
                            this.$store.commit('setPropertiesRoute',payload);
                            this.$router.push({name:'error'})
                        }
                    });
                })
                .catch(error =>
                    console.log(error)
                );
            }
        },
        mounted(){
            // Se verifica si hay credenciales guardadas en el navegador(Boton recuerdame)
            const cookie = Cookies.get('credenciales');

            if(cookie){
                const credenciales = JSON.parse(cookie)
                document.getElementById('usuario').value = credenciales.usuario;
                document.getElementById('contrasena').value = Crypt.AES.decrypt(credenciales.contrasena, appsettings.KeyEncript).toString(Crypt.enc.Utf8);
                document.getElementById('recuerdame').checked = credenciales.recuerdame? true : false;
                
            }
        },
        methods:{
            LogIn(){
                // Se recuperan credenciales ingresadas por el usuarios
                const usuario = document.getElementById("usuario").value;
                const contrasena = document.getElementById("contrasena").value;
                const recuerdame = document.getElementById("recuerdame");
                
                // Se encripta la contrasena para evitar que viaje en texto plano
                const contrasenaEncriptada = Crypt.AES.encrypt(contrasena, appsettings.KeyEncript).toString();

                if(recuerdame.checked){
                    Cookies.set("credenciales", JSON.stringify({usuario: usuario,contrasena:contrasenaEncriptada,recuerdame: true}), { expires: 7 });
                }
                else{
                    Cookies.remove("credenciales");
                }
                // Se validan credenciales contra el backend
                const payload ={
                    usuario: usuario,
                    contrasena: contrasenaEncriptada
                }

                Axios.post(appsettings.Backend + appsettings.UrlValidacionCredenciales,payload)
                .then(response => {
                    // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {
                            if(!response.data.Data.validado){
                                // Se actualiza info de popup
                                this.ActualizarPopUp("Autenticacion de Usuario","Usuario y/o contraseña invalidos",true);
                                return false;
                            }
                            else{
                                sessionStorage.setItem('usuario',JSON.stringify(response.data.Data.user))
                                sessionStorage.setItem('listapermisos',JSON.stringify(response.data.Data.permisos))                                
                                return true; 
                            }

                        }
                        else {
                            // Se actualiza info de popup
                            this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                            return false;
                        }
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                        return false;
                    }
                })
                .then(response => {
                    if(!response){
                        this.MostrarPopUp = true;
                    }
                    else{
                        // Se arma payload para solicitud LTI
                        const payload ={ iss: appsettings.Frontend, target_link_uri: appsettings.Backend, login_hint: 3, lti_message_hint: 2, client_id: "123456789", lti_deployment_id: 2 }
                        console.log("Payload /login: " + JSON.stringify(payload))
                        Axios.post(appsettings.Backend+"/login",payload)
                        .then(response => {
                            console.log("/login respondio")
                            console.log("Payload /: " + JSON.stringify(response.data))
                            // Se redirige la respuesta LTI al back
                            Axios.post(appsettings.Backend, response.data)
                            .then(response => {
                                // Se guarda el token y los permisos
                                console.log(response)
                                sessionStorage.setItem('token',response.data.token);
                       
                                this.EvaluarPermisos(getDataStorage('listapermisos'));
                                this.$router.push({name:'main'})
                                
                            })
                            .catch((error) => {
                                console.log(error)
                                let payload;
                                if(error.response){
  
                                    payload = {
                                        name: 'PageErrorComponent',
                                        props: {
                                            Codigo: error.response.status,
                                            Mensaje: error.response.statusText,
                                            Descripcion: ''
                                        }
                                    }
                                    
                                    this.$store.commit('setPropertiesRoute',payload);
                                    this.$router.push({name:'error'})
                                }
                                else{
                                    payload = {
                                        name: 'PageErrorComponent',
                                        props: {
                                            Codigo: '602',
                                            Mensaje: 'ERR_NETWORK',
                                            Descripcion: 'Error de red. No se ha podido establecer conexion con el Backend'
                                        }
                                    }
                                    this.$store.commit('setPropertiesRoute',payload);
                                    this.$router.push({name:'error'})
                                }
                            });
                        }).catch((error) => {
                            console.log(error)
                            let payload;
                                if(error.response){
  
                                    payload = {
                                        name: 'PageErrorComponent',
                                        props: {
                                            Codigo: error.response.status,
                                            Mensaje: error.response.statusText,
                                            Descripcion: ''
                                        }
                                    }
                                    
                                    this.$store.commit('setPropertiesRoute',payload);
                                    this.$router.push({name:'error'})
                                }
                                else{
                                    payload = {
                                        name: 'PageErrorComponent',
                                        props: {
                                            Codigo: '602',
                                            Mensaje: 'ERR_NETWORK',
                                            Descripcion: 'Error de red. No se ha podido establecer conexion con el Backend'
                                        }
                                    }
                                    this.$store.commit('setPropertiesRoute',payload);
                                    this.$router.push({name:'error'})
                                }
                        });
                    }
                })
                .catch((error) => {
                    console.log(error)
                    let payload;
                    if(error.response){
                        payload = {
                            name: 'PageErrorComponent',
                            props: {
                                Codigo: error.response.status,
                                Mensaje: error.response.statusText,
                                Descripcion: ''
                            }
                        }
                        this.$store.commit('setPropertiesRoute',payload);
                        this.$router.push({name:'error'})
                    }
                    else{
                        payload = {
                            name: 'PageErrorComponent',
                            props: {
                                Codigo: '602',
                                Mensaje: 'ERR_NETWORK',
                                Descripcion: 'Error de red. No se ha podido establecer conexion con el Backend'
                            }
                        }
                        this.$store.commit('setPropertiesRoute',payload);
                        this.$router.push({name:'error'})
                    }
                });
            },
            EvaluarPermisos(listaPermisos){

                let Turnos = {};
                let Estadisticas = {};
                let Usuarios={};
                let Experiencias={};
                let Hardwares={};
                let Laboratorios={};
                let Roles={};
                let Permisos={};
                let LTI={};
                let Calendario = {};
                let Inicio = {};
                let Universidades={};


                listaPermisos = JSON.parse(listaPermisos);

                listaPermisos.forEach(permiso=> {
                    const auxiliar = permiso.split("-");

                    if(auxiliar[0]=='gestion' && auxiliar[1]=='usuarios'){
                        
                        switch(auxiliar[2]){
                            case 'crear':   Usuarios.Crear = true;
                                            break;
                            case 'borrar':  Usuarios.Borrar = true;
                                            break;
                            case 'alta':    Usuarios.Alta = true;
                                            break;
                            case 'baja':    Usuarios.Baja = true;
                                            break;
                            case 'modificar':   Usuarios.Modificar = true;
                                                break;
                            case 'asignar': Usuarios.Asignar = true;
                                                break;
                        }
                    }
                    if(auxiliar[0]=='gestion' && auxiliar[1]=='experiencias'){
                        
                        switch(auxiliar[2]){
                            case 'crear':   Experiencias.Crear = true;
                                            break;
                            case 'borrar':  Experiencias.Borrar = true;
                                            break;
                            case 'alta':    Experiencias.Alta = true;
                                            break;
                            case 'baja':    Experiencias.Baja = true;
                                            break;
                            case 'modificar':   Experiencias.Modificar = true;
                                                break;
                        }
                    }

                    if(auxiliar[0]=='inicio' && auxiliar[1]=='experiencias'){
                        
                        switch(auxiliar[2]){
                            case 'ver':   Inicio.Ver = true;
                                            break;
                        }
                    }

                    if(auxiliar[0]=='gestion' && auxiliar[1]=='hardwares'){
                        
                        switch(auxiliar[2]){
                            case 'crear':   Hardwares.Crear = true;
                                            break;
                            case 'borrar':  Hardwares.Borrar = true;
                                            break;
                            case 'alta':    Hardwares.Alta = true;
                                            break;
                            case 'baja':    Hardwares.Baja = true;
                                            break;
                            case 'modificar':   Hardwares.Modificar = true;
                                                break;
                        }
                    }

                    if(auxiliar[0]=='gestion' && auxiliar[1]=='laboratorios'){
                        
                        switch(auxiliar[2]){
                            case 'crear':   Laboratorios.Crear = true;
                                            break;
                            case 'borrar':  Laboratorios.Borrar = true;
                                            break;
                            case 'alta':    Laboratorios.Alta = true;
                                            break;
                            case 'baja':    Laboratorios.Baja = true;
                                            break;
                            case 'modificar':   Laboratorios.Modificar = true;
                                                break;
                        }
                    }
                    if(auxiliar[0]=='gestion' && auxiliar[1]=='roles'){
                        
                        switch(auxiliar[2]){
                            case 'crear':   Roles.Crear = true;
                                            break;
                            case 'borrar':  Roles.Borrar = true;
                                            break;
                            case 'alta':    Roles.Alta = true;
                                            break;
                            case 'baja':    Roles.Baja = true;
                                            break;
                            case 'modificar':   Roles.Modificar = true;
                                                break;
                            case 'asignar':   Roles.Asignar = true;
                                                break;
                        }
                    }
                    if(auxiliar[0]=='gestion' && auxiliar[1]=='permisos'){
                        
                        switch(auxiliar[2]){
                            case 'crear':   Permisos.Crear = true;
                                            break;
                            case 'borrar':  Permisos.Borrar = true;
                                            break;
                            case 'alta':    Permisos.Alta = true;
                                            break;
                            case 'baja':    Permisos.Baja = true;
                                            break;
                            case 'modificar':   Permisos.Modificar = true;
                                                break;
                        }
                    }
                    if(auxiliar[0]=='gestion' && auxiliar[1]=='lti'){
                        
                        switch(auxiliar[2]){
                            case 'crear':   LTI.Crear = true;
                                            break;
                            case 'borrar':  LTI.Borrar = true;
                                            break;
                            case 'modificar':   LTI.Modificar = true;
                                                break;
                        }
                    }

                    if(auxiliar[0]=='gestion' && auxiliar[1]=='universidades'){
                        
                        switch(auxiliar[2]){
                            case 'crear':   Universidades.Crear = true;
                                            break;
                            case 'borrar':  Universidades.Borrar = true;
                                            break;
                            case 'alta':    Universidades.Alta = true;
                                            break;
                            case 'baja':    Universidades.Baja = true;
                                            break;
                            case 'modificar':   Universidades.Modificar = true;
                                                break;
                            case 'ver':   Universidades.Ver = true;
                                                break;
                        }
                    }

                    if(auxiliar[0]=='turnos' && auxiliar[1]=='calendario'){ 
                        switch(auxiliar[2]){
                            case 'crear':   Calendario.Crear = true;
                                            break;
                            case 'borrar':  Calendario.Borrar = true;
                                            break;
                            case 'modificar':   Calendario.Modificar = true;
                                                break;
                        }           
                    }

                    if(auxiliar[0]=='turnos' && auxiliar[1]=='solicitar'){    
                        if(auxiliar[2]=='propio'){
                            Turnos.SolicitarPropio = true;
                        }        
                        if(auxiliar[2]=='tercero'){
                            Turnos.SolicitarTercero = true;
                        }                        
                    }
                    if(auxiliar[0]=='turnos' && auxiliar[1]=='reprogramar'){    
                        if(auxiliar[2]=='propio'){
                            Turnos.ReprogramarPropio = true;
                        }        
                        if(auxiliar[2]=='tercero'){
                            Turnos.ReprogramarTercero = true;
                        }                        
                    }

                    if(auxiliar[0]=='turnos' && auxiliar[1]=='cancelar'){    
                        if(auxiliar[2]=='propio'){
                            Turnos.CancelarPropio = true;
                        }        
                        if(auxiliar[2]=='tercero'){
                            Turnos.CancelarTercero = true;
                        }                        
                    }

                    if(auxiliar[0]=='turnos' && auxiliar[1]=='ver'){    
                        if(auxiliar[2]=='propio'){
                            Turnos.VerPropio = true;
                        }        
                        if(auxiliar[2]=='tercero'){
                            Turnos.VerTercero = true;
                        }                        
                    }

                    if(auxiliar[0]=='turnos' && auxiliar[1]=='borrar'){    
                            Turnos.Borrar = true;
                    }

                    if(auxiliar[0]=='estadisticas' && auxiliar[1]=='ver'){    
                        if(auxiliar[2]=='propio'){
                            Estadisticas.SolicitarPropio = true;
                        }        
                        if(auxiliar[2]=='tercero'){
                            Estadisticas.SolicitarTercero = true;
                        }                        
                    }
                })

                const permisos = {
                    Usuarios: Usuarios,
                    Experiencias: Experiencias,
                    Hardwares: Hardwares,
                    Laboratorios: Laboratorios,
                    Roles: Roles,
                    Turnos:Turnos,
                    Permisos: Permisos,
                    LTI: LTI,
                    Estadisticas:Estadisticas,
                    Calendario: Calendario,
                    Inicio: Inicio,
                    Universidades: Universidades
                }
                sessionStorage.setItem("permisos",JSON.stringify(permisos));
                this.$store.commit('setPropertiesRoute',{name: 'Permisos',props: permisos});
            },
            Cancelar(){
                // Se quita popup de pantalla en caso de que el usuario haya cancelado la accion
                this.MostrarPopUp = false;
            },
            ActualizarPopUp(Titulo,Mensaje,Cerrar){
                // Se carga informacion al popup
                this.InfoPopUp = {
                    name: "PopUpComponent",
                    props: {
                        CierreHabilitado: Cerrar,
                        Titulo: Titulo,
                        Mensaje: Mensaje
                    }
                };
                this.$store.commit("setPropertiesRoute", this.InfoPopUp);
            },
            CerrarPopUp(){
                // Se cita el popup de pantalla cuando el cliente lo cierra
                this.MostrarPopUp = false;
            }
        },
        components:{
            PopUpComponent,
            AppFooter
        }
    }
  </script>

<style>
#app {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.container-fluid {
    background-color: #f8f8f8;
    height: 100%;
}

.checkbox-container {
  text-align: center;
}

.checkbox-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-text {
  padding-left: 30px;
}

.checkbox-input:checked ~ .checkbox-custom {
  background-color: #4e73df;
  border-color: #4e73df;
}

.checkbox-input:checked ~ .checkbox-custom::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border: solid #fff;
  border-width: 0 3px 3px 0; /* Cambiar el border-width */
  transform: rotate(-45deg);
}

</style>