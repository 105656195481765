<template>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-12">
                <form class="user">
                    <div class="row">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4">
                            <h1 class="h4 text-gray-900 mb-4">Nuevo Dia no Laboral</h1>
                        </div>
                        <div class="col-lg-4"></div>
                        <span class="close-button" v-on:click="CerrarForm">X</span>
                    </div>

                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label class="col-lg-12">Fecha Desde (DD-MM)</label>
                                <input class="form-control form-control-user input" type="text" id="fechaDesde">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label class="col-lg-12">Fecha Hasta (DD-MM)</label>
                                <input class="form-control form-control-user input" type="text" id="fechaHasta" v-on:input="Validar" >
                            </div>
                        </div>
                        <div class="col-lg-3"></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-1"></div>
                            <div class="col-lg-8">
                                <div class="form-group">
                                    <label class="col-lg-12">Descripcion</label>
                                    <input class="form-control form-control-user input" type="text" id="descripcion">
                                </div>
                            </div>
                            <div class="col-lg-1"></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-10">
                            <hr>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4"></div>
                  
                            <a class="col-lg-3 content btn btn-primary btn-user btn-block" @click="Aceptar">
                            Aceptar
                            </a>
    
                        <div class="col-lg-4"></div>
                    </div>

                </form>
            </div>
        </div> 

    </div>

    <PopUpComponent v-if="MostrarPopUp" @CerrarPopUp="CerrarPopUp()"></PopUpComponent>

</template>

<script>
    import PopUpComponent from './commons/PopUpComponent.vue';
    import { appsettings } from '../../appsettings';
    import Axios from 'axios';

    export default {
        data() {
            return {
                UrlsCalendario:[],
                MostrarPopUp: false,
            };
        },
        created(){
            //Se traen urls del modulo a gestionar
            appsettings.UrlsBackend.forEach(modulo => {
                if ("Calendario" in modulo) {
                    this.UrlsCalendario = modulo["Calendario"];
                }
            });
        },
        methods: {
            Aceptar(){
                const payload={
                    fechaDesde: document.getElementById("fechaDesde").value,
                    fechaHasta: document.getElementById("fechaHasta").value,
                    descripcion: document.getElementById("descripcion").value
                }
                // Se envia peticion a backend para creacion
                Axios.post(appsettings.Backend + this.UrlsCalendario.Crear, payload)
                    .then(response => {
                    // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {
                            // Se actualiza info de popup
                            this.ActualizarPopUp("Creacion de Dia No Laboral",response.data.Data,true);
                            this.MostrarPopUp=true;
                        }
                        else {
                            // Se actualiza info de popup
                            this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                            this.MostrarPopUp=true;
                        }
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                        this.MostrarPopUp=true;
                    }
                    this.Limpiar();
                })
                .catch(error => {
                    console.log(error);
                    // Se actualiza info de popup
                    this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                    // Se muestra popup en pantalla
                    this.MostrarPopUp = true;
                    this.Limpiar();
                });
            },
            ActualizarPopUp(Titulo,Mensaje,Cerrar){
                // Se carga informacion al popup
                const payload = {
                    name: "PopUpComponent",
                    props: {
                        CierreHabilitado: Cerrar,
                        Titulo: Titulo,
                        Mensaje: Mensaje
                    }
                };
                this.$store.commit("setPropertiesRoute", payload);
            },
            CerrarPopUp(){
                // Se cita el popup de pantalla cuando el cliente lo cierra
                this.MostrarPopUp = false;
            },
            CerrarForm(){
                this.$router.go(-1);
            },
            Limpiar(){
                document.getElementById("fechaDesde").value='';
                document.getElementById("fechaHasta").value ='';
                document.getElementById("descripcion").value ='';
            }
        },
        components:{
            PopUpComponent
        }
    };
</script>

<style scoped>
.tablestyle {
    font-size: 12px;  
    text-align:center; 
    width: auto; 
    vertical-align: middle;
}

.autocomplete-list {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.autocomplete-list ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style: none;
}

.autocomplete-list ul li {
  padding: 5px;
  cursor: pointer;
}

.autocomplete-list ul li:hover {
  background-color: #f1f1f1;
}
.custom-tooltip {
  position: relative;
}
.custom-tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -30px;
  transform: translateX(-50%);
}
.custom-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -6px;
  border-top: 6px solid #333;
  border-bottom: none;
}
.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
  visibility: visible;
  opacity: 1;
}

.button-horario {
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.button-horario-selected {
  padding: 8px 12px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #3a86ff;
  color: #fff;
  cursor: pointer;
}

.scroll {
  min-height: 190px;
  max-height: 223px; 
  overflow-y: auto;
}

.input {
  border: 1px solid;
  border-color: gainsboro;
}

.input.invalid {
  border-color: red;
}

.input.valid {
  border-color: green;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
}
</style>