<template>
    <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body contenedor-imagen">
                    <img src="../../public/assets/img/perfil4.jpeg">
                </div>
            </div> 
            <div class="col-lg-12 mb-4"></div>         
            <div class="col-lg-12">
                <a class="btn btn-primary btn-user btn-block">
                    Editar Foto
                </a>
            </div>
        </div>
        <div class="col-lg-7">
            <div class="card">
                <div class="card-body" style="height-min: 450px;">
                    <div class=" row d-sm-flex align-items-center justify-content-center mb-12">
                        <h1 class="h3 mb-0 text-gray-800 my-3">Informacion del Usuario</h1>
                    </div>
                    <div class="row mb-4"></div>
                    <div class="row">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-6">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11">
                                <label class="text-gray-900">Apellido y Nombre</label>
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11 mb-4">
                                <label>{{ Usuario['nombre'] + ' ' + Usuario['apellido'] }}</label>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11">
                                <label class="text-gray-900">DNI</label>
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11 mb-4">
                                <label>{{ Usuario['dni'] }}</label>
                            </div>
                        </div>
                        <div class="col-lg-2"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-6">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11">
                                <label class="text-gray-900">Email</label>
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11 mb-4">
                                <label>{{ Usuario['correoElectronico'] }}</label>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11">
                                <label class="text-gray-900">Universidad</label>
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11 mb-4">
                                <label>{{ Usuario['universidad'] }}</label>
                            </div>
                        </div>
                        <div class="col-lg-2"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-6">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11">
                                <label class="text-gray-900">Roles</label>
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11">
                                <label>{{ Roles }}</label>
                            </div>
                
                        </div>
                        <div class="col-lg-2">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11">
                                <label class="text-gray-900">Estado</label>
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11 mb-4">
                                <label>{{ Usuario['estado'] }}</label>
                            </div>
                        </div>
                        <div class="col-lg-2"></div>
                    </div>
                    <div class="row mb-4"></div>
                    <div class="row">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4">
                            <a class="btn btn-primary btn-user btn-block">
                                Editar Perfil
                            </a>
                        </div>
                        <div class="col-lg-4"></div>
                    </div>
                </div>
            </div>  
        </div>
        <div class="col-lg-1"></div>
    </div>

</template>

<script>
    import { appsettings } from '../../appsettings';
    import Axios from 'axios';
    import { getDataStorage } from '../utils/StorageUtils';

    export default{
        data(){
            return{
                Usuario: {},
                Roles: '',
                UrlsUsuarios: {}
            } 
        },
        created(){
            this.Usuario = JSON.parse(getDataStorage("usuario"));

            // Se traen urls del modulo a gestionar
            appsettings.UrlsBackend.forEach(modulo => {
                if ("Usuarios" in modulo) {
                    this.UrlsUsuarios = modulo["Usuarios"];
                }
            });
            
            Axios.post(appsettings.Backend + this.UrlsUsuarios.ListarRolesDeUsuario, this.Usuario)
            .then(response => {
                // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                if (response.data.ErrorOperacion == 0) {
                    if (response.data.ErrorInesperado == 0) {
                        const auxiliar = response.data.Data;
                        let roles = '';
                        console.log(auxiliar)
                        if (auxiliar) {
                            auxiliar.forEach(rol => {
                                roles = roles + rol['rol'] + ' | ';
                            });

                            // Eliminar el último carácter de la cadena
                            this.Roles = roles.slice(0,roles.length-2)
                        }
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });

        }
    }
</script>

<style scoped>
.contenedor-imagen {
  width: 100%; /* Establece el ancho deseado para el contenedor */
  height: 250px; /* Establece la altura deseada para el contenedor */
  position: relative; /* Opcional, puede ser necesario en algunos casos */
}

.contenedor-imagen img {
  width: 100%; /* Hace que la imagen ocupe el 100% del ancho del contenedor */
  height: 100%; /* Hace que la imagen ocupe el 100% de la altura del contenedor */
  object-fit: cover; /* Opcional, controla cómo se ajusta la imagen dentro del contenedor */
}
</style>