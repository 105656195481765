<template>
  
  <section class="row d-flex justify-content-start flex:wrap">
    <div v-for="Experiencia in Experiencias" :key="Experiencia" class="col-md-4">
      <article class="card h-100 shadow mb-4">
        <div class="card-header">
          <h6 class="text-primary text-center font-weight-bold">{{ Experiencia.nombre }}</h6>
        </div>
        <div class="card-body">
          <img class="card-img" src="../../public/assets/img/003-telecomunicacions-5g-1.jpg">
          <div class="card-text text-center my-2">
            <p>{{ Experiencia.descripcion }}</p>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-center align-items-center">
          <a v-on:click="ValidarTurnoDeExperiencia(Experiencia)" class="card-link btn btn-primary btn-icon-split">
            <span class="icon text-white-50">
              <i class="fas fa-arrow-right"></i>
            </span>
            <span class="text">Acceder a Experiencia</span>
          </a>
        </div>
      </article>  
    </div>
  </section>

  <PopUpComponent v-if="MostrarPopUp" @CerrarPopUp="CerrarPopUp()"></PopUpComponent> 

</template>

<script>
  import Axios from 'axios';
  import { appsettings } from '../../appsettings';
  import { getDataStorage } from '../utils/StorageUtils';
  import PopUpComponent from './commons/PopUpComponent.vue';

  export default {
    data(){
      return{
        Experiencias: [],
        Urls: [],
        UrlsTurnos: [],
        PermisosModulo: {},
        MostrarPopUp: false,
        InfoPopUp:{
                Titulo: '',
                Mensaje: '',
                Cerrar: false
            }
      }
    },
    created(){
        // Se traen urls del modulo a gestionar
        appsettings.UrlsBackend.forEach(modulo => {
            if ("Experiencias" in modulo) {
                this.Urls = modulo["Experiencias"];
            }
            if ("Turnos" in modulo) {
                this.UrlsTurnos = modulo["Turnos"];
            }
        });

        // Se traen los permisos para el modulo
        const permisos = JSON.parse(getDataStorage('permisos'));
        this.PermisosModulo = permisos.Inicio;
        let bandera = false;
        
        if(!(Object.keys(permisos.LTI).length>0 || Object.keys(permisos.Permisos).length>0 || Object.keys(permisos.Roles).length>0 || Object.keys(permisos.Usuarios).length>0 || Object.keys(permisos.Experiencias).length>0 || Object.keys(permisos.Laboratorios).length>0 || Object.keys(permisos.Calendario).length>0 || Object.keys(permisos.Estadisticas).length>0 || Object.keys(permisos.Inicio).length>0 || Object.keys(permisos.Turnos).length>0)){
            this.ActualizarPopUp("Usuario Inactivo","No posee permisos para hacer uso de Labrem. Si considera que se trata de un error comuniquese con un administrador",true);
            this.MostrarPopUp = true;
            bandera = true;
        }

        if(Object.keys(this.PermisosModulo).length>0 && this.PermisosModulo.Ver){
          this.Listar();
        }
        else{
            if(!bandera){
              this.ActualizarPopUp("Experiencias","No posee permisos para visualizar experiencias. Si considera que se trata de un error comuniquese con un administrador",true);
              this.MostrarPopUp = true;
            }
        }
        
    },
    methods:{
      Listar() {
            // Se envia peticion a backend
            Axios.post(appsettings.Backend + this.Urls.Listar)
            .then(response => {
                // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                if (response.data.ErrorOperacion == 0) {
                    if (response.data.ErrorInesperado == 0) {
                        this.Experiencias = response.data.Data;
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        ValidarTurnoDeExperiencia(Experiencia){
          // Se envia peticion a backend
          const payload = {
            ...JSON.parse(getDataStorage('usuario')),
            ...Experiencia
          }
          console.log(this.UrlsTurnos)
          Axios.post(appsettings.Backend + this.UrlsTurnos.ValidarTurnoEnExperiencia, payload)
            .then(response => {
                // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                if (response.data.ErrorOperacion == 0) {
                    if (response.data.ErrorInesperado == 0) {
                        if(response.data.Data.validado){

                            location.href = Experiencia["url"] + '?token=' + response.data.Data.data
                        }
                        else{
                          this.ActualizarPopUp("Turnos","Para poder realizar la experiencia debe contar con un turno previo",true);
                          this.MostrarPopUp = true;
                        }
                        
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });

        },
        ActualizarPopUp(Titulo,Mensaje,Cerrar){
            // Se carga informacion al popup
            this.InfoPopUp = {
                name: "PopUpComponent",
                props: {
                    CierreHabilitado: Cerrar,
                    Titulo: Titulo,
                    Mensaje: Mensaje
                }
            };
            this.$store.commit("setPropertiesRoute", this.InfoPopUp);
        },
        CerrarPopUp(){
            // Se cita el popup de pantalla cuando el cliente lo cierra
            this.MostrarPopUp = false;

        }
    },
    components:{
        PopUpComponent
    }
  }
  </script>

<style scoped>

</style>