<template>
    <form class="user">
        <div class="row">

            <div class="col-lg-4">
                <div class="form-group">
                    <label class="col-lg-12">Usuario</label>
                    <input class="form-control form-control-user" type="text" id="buscarUsuarioEjecutante" :value="Usuario['apellido']+' '+Usuario['nombre']" :disabled="true">
                </div>
            </div>
            <div class="col-lg-8">
                <div class="form-group">
                    <label class="col-lg-12">Experiencia</label>
                    <input v-on:input="BuscarExperiencia()" class="form-control form-control-user input" type="text" id="buscarExperiencia">
                    <div v-if="MostrarLista" class="autocomplete-list">
                        <ul>
                            <li v-for="Experiencia in Experiencias" :key="Experiencia" v-on:click="CompletarExperiencia(Experiencia)">{{ Experiencia['nombre'] }}</li>
                            <li  v-if="MostrarMensaje">No hay coincidencias</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group">
                    <label class="col-lg-12">Fecha Solicitud</label>
                    <input class="form-control form-control-user input" id="buscarFechaSolicitud" type="date" v-on:change="Validar('buscarFechaSolicitud')">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label class="col-lg-12">Fecha Realizacion</label>
                    <input class="form-control form-control-user input" id="buscarFechaRealizacion" type="date" v-on:change="Validar('buscarFechaRealizacion')">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="row">
                    <label class="col-lg-12" style="visibility: hidden;">Realizar Busqueda</label>
                    <div class="col-lg-6 text-center my-1">
                        <a class="btn btn-primary btn-user btn-block" v-on:click="BuscarTurnos">
                            Buscar
                        </a>
                    </div>
                    <div class="col-lg-6 text-center my-1">
                        <a class="btn btn-primary btn-user btn-block" v-on:click="Limpiar">
                            Limpiar
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <hr>
            </div>
        </div>
    </form>


    <div id="wrapper">
        <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Turnos</h6>
                        </div>
                        <div class="card-body">  
                            <div class="table-responsive">
                                <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table class="table table-bordered dataTable no-footer" id="dataTable" width="100%" cellspacing="0" role="grid" aria-describedby="dataTable_info" style="width: 100%;">
                                                <thead>
                                                    <tr >
                                                        <th class="tablestyle">ID</th>
                                                        <th class="tablestyle">Usuario Ejecutante</th>
                                                        <th class="tablestyle">Usuario Solicitante</th>
                                                        <th class="tablestyle">Experiencia</th>
                                                        <th class="tablestyle">Fecha Solicitud</th>
                                                        <th class="tablestyle">Fecha Realizacion</th>
                                                        <th class="tablestyle">Hora Realizacion</th>
                                                        <th class="tablestyle">Estado</th>                                                    
                                                        <th class="tablestyle" v-if="PermisosModulo.ReprogramarPropio || PermisosModulo.CancelarPropio">Acciones</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                            
                                                    <tr v-for="Turno in Turnos" :key='Turno'>
                                                        <td class="tablestyle" style="height: 50px;" id="idTurno" colspan="1">{{ Turno['idTurno'] }} </td>
                                                        <td class="tablestyle" style="height: 50px;" id="usuarioEjecutante" colspan="1">{{ Turno['usuarioEjecutante'] }} </td>
                                                        <td class="tablestyle" style="height: 50px;" id="usuarioSolicitante" colspan="1">{{ Turno['usuarioSolicitante'] }} </td>
                                                        <td class="tablestyle" style="height: 50px;" id="experiencia" colspan="1">{{ Turno['experiencia'] }} </td>
                                                        <td class="tablestyle" style="height: 50px;" id="fechaSolicitud" colspan="1">{{ Turno['fechaSolicitud'] }} </td>
                                                        <td class="tablestyle" style="height: 50px;" id="fechaRealizacion" colspan="1">{{ Turno['fechaRealizacion'] }} </td>
                                                        <td class="tablestyle" style="height: 50px;" id="horaRealizacion" colspan="1">{{ Turno['horaRealizacion'] }} </td>
                                                        <td class="tablestyle" style="height: 50px;" id="estado">{{ Turno['estado'] }} </td>
                                                        <td class="tablestyle" style="width: 200px;" v-if="PermisosModulo.ReprogramarPropio || PermisosModulo.CancelarPropio">
                                                            <div class="d-flex">
                                                                <a v-if="PermisosModulo.ReprogramarPropio" class="btn btn-success btn-circle custom-tooltip" data-tooltip="Reprogramar" style="background-color: #ff9800; border: none;" v-on:click="Reprogramar(Turno)">
                                                                    <i class="fas fa-arrow-right"></i>
                                                                </a>
                                                                <a v-if="PermisosModulo.CancelarPropio" class="btn btn-warning btn-circle custom-tooltip" data-tooltip="Cancelar" v-on:click="Accion(Turno,'Cancelar')">
                                                                    <i class="fas fa-exclamation-triangle"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-5">
                                            <div class="dataTables_info" id="dataTable_info" role="status" aria-live="polite">{{ CantFilas }} de {{ CantFilas }}</div>
                                        </div>
                                        <div class="col-sm-12 col-md-7">
                                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                                <ul class="pagination">
                                                    <li class="paginate_button page-item previous enabled" id="dataTable_previous">
                                                        <a v-on:click="VolverPagina" id="Anterior" aria-controls="dataTable" data-dt-idx="0" tabindex="0" class="page-link">Anterior</a>
                                                    </li>
                                                    <li class="paginate_button page-item active">
                                                        <a  aria-controls="dataTable" data-dt-idx="1" tabindex="0" class="page-link">{{ this.NumeroPagina+1 }}</a>
                                                    </li>
                                                    <li class="paginate_button page-item next enabled" id="dataTable_next">
                                                        <a v-on:click="PasarPagina" id="Posterior" aria-controls="dataTable" data-dt-idx="2" tabindex="0" class="page-link">Posterior</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <PopUpComponent v-if="MostrarPopUpA" @CerrarPopUp="CerrarPopUp()">
            <div class="col-lg-12 center">
                <a class="col-lg-8 content btn btn-primary btn-user btn-block" v-on:click="Aceptar">
                    Aceptar
                </a>
            </div>
            <div class="col-lg-12 center">
                <a class="col-lg-8 content btn btn-primary btn-user btn-block" v-on:click="Cancelar()">
                    Cancelar
                </a>
            </div>
        </PopUpComponent> 

        <PopUpComponent v-if="MostrarPopUpB" @CerrarPopUp="CerrarPopUp()"></PopUpComponent>

</template>

<script>

    import PopUpComponent from './commons/PopUpComponent.vue';
    import { appsettings } from '../../appsettings';
    import Axios from 'axios';
    import { getDataStorage } from '../utils/StorageUtils';

    export default {
        data() {
            return {
                    CantFilas: 0,
                    NumeroPagina: 0,
                    Bandera: true,
                    UrlsExperiencias: [],
                    UrlsTurnos:[],
                    Turnos: [],
                    Turno: {},
                    Usuario: null,
                    Experiencias: [],
                    Experiencia: null,
                    MostrarPopUpA: false,
                    MostrarPopUpB: false,
                    MostrarMensaje: false,
                    MostrarLista: false, 
                    AccionRealizada: null 
            };
        },
        created(){
            // Se traen los permisos para el modulo
            const permisos = JSON.parse(getDataStorage("permisos"));
            this.PermisosModulo = permisos.Turnos;
            
            //Se trae el usuario de sesion storage
            this.Usuario = JSON.parse(getDataStorage("usuario"));

            //Se traen urls del modulo a gestionar
            appsettings.UrlsBackend.forEach(modulo => {
                if ("Experiencias" in modulo) {
                    this.UrlsExperiencias = modulo["Experiencias"];
                }
                if ("Turnos" in modulo) {
                    this.UrlsTurnos = modulo["Turnos"];
                }
            });
        },
        methods: {
            BuscarExperiencia(){

                const input = document.getElementById('buscarExperiencia');
                input.classList.remove('valid')
                input.classList.add('invalid')

                // Se realiza la peticion de busqueda de usuarios a partir de tres caracteres
                let Cadena = document.getElementById('buscarExperiencia').value;
                
                if (Cadena == 0) {
                    this.MostrarLista = false;
                    this.MostrarMensaje = false;
                    this.Experiencias = null;
                }
                if (Cadena.length >= 3) {
                    // Se arma payload de la solicitud http
                    const payload = {
                        "cadena": Cadena
                    };

                    // Se envia peticion a backend
                    Axios.post(appsettings.Backend + this.UrlsExperiencias.Buscar, payload)
                    .then(response => {
                        // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                        if (response.data.ErrorOperacion == 0) {
                            if (response.data.ErrorInesperado == 0) {
                                this.Experiencias = response.data.Data;
                                if(!this.Experiencias){
                                    this.MostrarMensaje = true;
                                }
                                else{
                                    this.MostrarMensaje = false;
                                }
                                this.MostrarLista = true;
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            },
            CompletarExperiencia(Experiencia){
                const input = document.getElementById('buscarExperiencia');
                input.value = Experiencia['nombre']
                input.classList.remove('invalid');
                input.classList.add('valid');
                this.Experiencia = Experiencia;
                this.MostrarLista = false
            },
            BuscarTurnos(){
                //Se arma pyload de solicitud 
                const payload = {
                    bloque: this.NumeroPagina,
                    usuarioE: this.Usuario,
                    usuarioS: null,
                    experiencia: this.Experiencia,
                    fechaSolicitud: document.getElementById('buscarFechaSolicitud').value,
                    fechaInicio: document.getElementById('buscarFechaRealizacion').value
                }

                // Se envia peticion a backend
                Axios.post(appsettings.Backend + this.UrlsTurnos.Buscar, payload)
                .then(response => {
                    // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {
                            this.Turnos = response.data.Data;
                            if(!this.Turnos){
                                if(this.Bandera){
                                    this.ActualizarPopUp("Turnos","No hay coincidencias con los filtros seleccionados", true);
                                    this.MostrarPopUp = true;
                                }
                                this.CantFilas = 0;
                            }
                            else{
                                this.CantFilas = this.Turnos.length;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });           
            },
            Validar(idInput){
                const inputFecha = document.getElementById(idInput);
                
                if(!(inputFecha.value === '')){
                    inputFecha.classList.remove('invalid');
                    inputFecha.classList.add('valid');
                }
                else{
                    inputFecha.classList.remove('valid');
                    inputFecha.classList.add('invalid');
                }
            },
            ActualizarPopUp(Titulo,Mensaje,Cerrar){
                // Se carga informacion al popup
                const payload = {
                    name: "PopUpComponent",
                    props: {
                        CierreHabilitado: Cerrar,
                        Titulo: Titulo,
                        Mensaje: Mensaje
                    }
                };
                this.$store.commit("setPropertiesRoute", payload);
            },
            CerrarPopUp(){
                // Se cita el popup de pantalla cuando el cliente lo cierra
                this.MostrarPopUpA = false;
                this.MostrarPopUpB = false;
            },
            Limpiar(){
                //Se obtienen los inputs a limpiar
                const experiencia = document.getElementById('buscarExperiencia');
                const fechaS = document.getElementById('buscarFechaSolicitud');
                const fechaR = document.getElementById('buscarFechaRealizacion');

                //Se limpian todos los inputs
                experiencia.value = '';
                experiencia.classList.remove('valid');
                experiencia.classList.remove('invalid');
                fechaS.value = '';
                fechaS.classList.remove('valid');
                fechaS.classList.remove('invalid');
                fechaR.value = '';
                fechaR.classList.remove('valid');
                fechaR.classList.remove('invalid');

                //Se limpian las variables que contienen datos
                this.Experiencias = null;
                this.Experiencia = null;
                this.Turnos = null;

            },
            PasarPagina() {
                // Se pide al backend el siguiente bloque de datos
                this.Bandera = false;
                this.NumeroPagina = this.NumeroPagina + 1;
                this.BuscarTurnos();
                setTimeout(function() {
                    if (this.CantFilas < 1) {
                        this.NumeroPagina = this.NumeroPagina - 1;
                        this.BuscarTurnos();
                        this.Bandera = true;
                    }
                }.bind(this), 300);
            },
            VolverPagina() {
                // Se pide al backend el anterior bloque de datos 
                this.Bandera = false;
                if (this.NumeroPagina != 0) {
                    this.NumeroPagina = this.NumeroPagina - 1;
                    this.BuscarTurnos();
                    setTimeout(function() {
                        if (this.CantFilas < 1) {
                            this.NumeroPagina = this.NumeroPagina + 1;
                            this.BuscarTurnos();
                            this.Bandera = true;
                        }
                    }.bind(this), 300);
                }
            },
            Accion(Turno,Accion){
                // Se muestra popup para que el usuario conforme la accion que realizara
                this.Turno = Turno;
                this.AccionRealizada = Accion;

                // Se actualiza info de popup
                this.ActualizarPopUp("Confirmacion","Esta seguro que desea realizar la accion?",false);
                this.MostrarPopUpA = true;
            },
            Aceptar(){
                // Se ejecuta accion a realizar por el usuario de acuerdo al boton elegido
                this.MostrarPopUpA = false;
                switch(this.AccionRealizada){
                    case 'Cancelar':    
                        this.CancelarTurno(this.Turno);
                        break;
                }
            },
            Cancelar(){
                // Se quita popup de pantalla en caso de que el usuario haya cancelado la accion
                this.MostrarPopUpA = false;
                this.MostrarPopUpB = false;
            },
            Reprogramar(Turno){
                this.$store.commit('setPropertiesRoute',{name:'TurnoAReprogramar',props:Turno});
                this.$router.push({name:'reprogramarturno'})
            },
            CancelarTurno(Turno){
                // Se envia peticion al backend
                Axios.post(appsettings.Backend + this.UrlsTurnos.Cancelar, Turno)
                .then(response => {
                    // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {

                            this.ActualizarPopUp("Solicitud de Turnos",response.data.Data,true);
                            this.MostrarPopUpB = true;
                        }
                        else{
                            // Se actualiza info de popup
                            this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                            this.MostrarPopUpB = true;
                        }
                    }
                    else{
                        this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                        this.MostrarPopUpB = true;
                    }
                    this.Limpiar()
                })
                .catch(error => {
                    // Se actualiza info de popup
                    this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                    this.MostrarPopUpB = true;
                    console.log(error);
                });
            }
        },
        components:{
            PopUpComponent
        }
    };
</script>

<style scoped>
.tablestyle {
    font-size: 12px;  
    text-align:center; 
    width: auto; 
    vertical-align: middle;
}

.autocomplete-list {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.autocomplete-list ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style: none;
}

.autocomplete-list ul li {
  padding: 5px;
  cursor: pointer;
}

.autocomplete-list ul li:hover {
  background-color: #f1f1f1;
}
.custom-tooltip {
  position: relative;
  margin-inline: 5px;
}
.custom-tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -30px;
  transform: translateX(-50%);
}
.custom-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -6px;
  border-top: 6px solid #333;
  border-bottom: none;
}
.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
  visibility: visible;
  opacity: 1;
}

.input {
  border: 1px solid;
  border-color: gainsboro;
}

.input.invalid {
  border-color: red;
}

.input.valid {
  border-color: green;
}

.center {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>