import Vuex from 'vuex'

export const Store = new Vuex.Store({
    state: {
        Permisos: {},
        FormComponent: {},
        PageErrorComponent: {},
        PopUpComponent: {},
        Laboratorio: {},
        Usuario: {},
        Rol:{},
        TurnoAReprogramar: {}
    },
    mutations: {
      setPropertiesRoute(state,payload){
        const key = payload.name;
        if (key in state) {
            state[key] = payload.props;
        }
      }
    }
  })