<template>
    <div class="modulo">
        <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-center mb-12">
                <h1 class="h3 mb-0 text-gray-800 my-3">Asignacion de Permisos</h1>
            </div>

            <div class="card shadow mb-3 ">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">Rol</h6>
                </div>
                <div class="card-body">  
                    <div class="table-responsive">
                        <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div class="row">
                                <div class="col-sm-12">
                                    {{ Rol['rol'] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="row">
                <div class="col-lg-6">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Permisos Disponibles</h6>
                        </div>
                        <div class="card-body global">
                            <div v-for="Permiso in PermisosDisponibles" :key="Permiso" :id="'drag'+Permiso.permiso" draggable="true" v-on:dragstart="Drag($event,Permiso)">
                                <div  class="d-sm-flex align-items-center justify-content-center mb-12">
                                    <div class="col-lg-4">
                                        <a style="width: 200px;" @click="VerDescripcion(Permiso)">{{ Permiso["permiso"] }}</a>
                                    </div>
                                    <div class="col-lg-4" style="visibility: hidden;">{{ Permiso['estado']=='A'? "Alta" : "Baja" }}</div>
                                    <div class="col-lg-4" style="visibility: hidden;">
                                        <div style="height: 50px;">
                                            <a class="btn btn-success btn-circle" @click="Accion(Permiso,'Alta')">
                                                <i class="fas fa-check"></i>
                                                </a>
                                                <a class="btn btn-warning btn-circle" @click="Accion(Permiso,'Baja')">
                                                <i class="fas fa-exclamation-triangle"></i>
                                                </a>
                                                <a class="btn btn-danger btn-circle" @click="Accion(Permiso,'Quitar')">
                                                <i class="fas fa-trash"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            
                <div class="col-lg-6">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Permisos Asignados</h6>
                        </div>
                        <div class="card-body global" >
                            <div id="contenedorDrag" v-on:drop="Drop($event)" v-on:dragover="AllowDrop($event)" style="height: 150px;">
                                <div v-for="Permiso in PermisosDeRol" :key="Permiso" :id="'drag'+Permiso.permiso" draggable="true" v-on:dragstart="Drag($event)">
                                    <div  class="d-sm-flex align-items-center justify-content-center mb-12">
                                        <div class="col-lg-4">
                                            <a style="width: 150px;" @click="VerDescripcion(Permiso)">{{ Permiso["permiso"] }}</a>
                                        </div>
                                        <div class="col-lg-4">{{ Permiso['estado']=='A'? "Alta" : "Baja" }}</div>
                                        <div class="col-lg-4">
                                            <div style="height: 50px;">
                                                <a class="btn btn-success btn-circle" @click="Accion(Permiso,'Alta')">
                                                <i class="fas fa-check"></i>
                                                </a>
                                                <a class="btn btn-warning btn-circle" @click="Accion(Permiso,'Baja')">
                                                <i class="fas fa-exclamation-triangle"></i>
                                                </a>
                                                <a class="btn btn-danger btn-circle" @click="Accion(Permiso,'Quitar')">
                                                <i class="fas fa-trash"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            
            </div>
        
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">Descripcion de Permiso ({{ PermisoSeleccionado }})</h6>
                </div>
                <div class="card-body">  
                    <div class="table-responsive">
                        <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div class="row">
                                <div class="col-sm-12">
                                    {{ Descripcion }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <PopUpComponent v-if="MostrarPopUpA" @CerrarPopUp="CerrarPopUp()">
        <div class="col-lg-12 center">
            <a class="col-lg-8 content btn btn-primary btn-user btn-block" v-on:click="Aceptar">
                Aceptar
            </a>
        </div>
        <div class="col-lg-12 center">
            <a class="col-lg-8 content btn btn-primary btn-user btn-block" v-on:click="Cancelar()">
                Cancelar
            </a>
        </div>
    </PopUpComponent> 

    <PopUpComponent v-if="MostrarPopUpB" @CerrarPopUp="CerrarPopUp()"></PopUpComponent> 

</template>
    
<script>
    
    import Axios from 'axios';
    import { appsettings } from '../../appsettings';
    import PopUpComponent from './commons/PopUpComponent.vue';
    import { getDataStorage } from '../utils/StorageUtils';
    
    export default{
        data(){
            return{
                Rol:{},
                Permisos: [],
                PermisosDisponibles: [],
                PermisosDeRol: [],
                UrlsPermisos: [],
                UrlsUsuarios: [],
                UrlsRoles: [],
                PermisosModulo: [],
                Elemento: {},
                MostrarPopUpA: false,
                MostrarPopUpB: false,
                MostrarPermisos: false,
                PermisoSeleccionado: 'Seleccione Permiso',
                Permiso: {},
                AccionRealizada: null,
                Descripcion: null

            }
        },
        created(){
            // Se traen los permisos para el modulo
            const permisos = JSON.parse(getDataStorage('permisos'));
            this.PermisosModulo = permisos.Permisos;

            // Se trae el usuario que se esta gestionando
            this.Rol = JSON.parse(this.$store.state.Rol);

            // Se traen urls del modulo a gestionar
            appsettings.UrlsBackend.forEach(modulo => {
                if ("Permisos" in modulo) {
                    this.UrlsPermisos = modulo["Permisos"];
                }
                if ("Roles" in modulo) {
                    this.UrlsRoles = modulo["Roles"];
                }
                if ("Usuarios" in modulo) {
                    this.UrlsUsuarios = modulo["Usuarios"];
                }
            });

            this.CargarDatos();
        },
        methods:{
            CargarDatos(){

                //Se traen los permisos del rol para mostrar
                Axios.post(appsettings.Backend + this.UrlsRoles.ListarPermisosDeRol,this.Rol)
                .then(response => {
                    // Se evalua respuesta del mismo y se carga informacion
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {
                            this.PermisosDeRol = response.data.Data;
                            return true;
                        }
                    }
                    else{
                        return false;
                    }
                })
                .then(response => {
                    if(response){
                        // Se traen los permisos disponibles para asignar
                        Axios.post(appsettings.Backend + this.UrlsPermisos.Listar)
                        .then(response => {
                            // Se evalua respuesta del mismo y se carga informacion
                            if (response.data.ErrorOperacion == 0) {
                                if (response.data.ErrorInesperado == 0) {

                                    this.Permisos = response.data.Data;
                                    
                                    let auxiliar = [].concat(this.Permisos);
                                    let indice;

                                    for(let i=0;i<this.Permisos.length;i++){
                                        for(let j=0;j<this.PermisosDeRol.length;j++){
                                            if(this.Permisos[i].idPermiso == this.PermisosDeRol[j].idPermiso){
                                                for(let k=0;k<auxiliar.length;k++){
                                                    if(auxiliar[k].idPermiso==this.PermisosDeRol[j].idPermiso){
                                                        indice = k
                                                    }
                                                }
    
                                                auxiliar.splice(indice,1);
                                            }
                                        }
                                    }
   
                                    this.PermisosDisponibles = auxiliar;
                                }
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
            Agregar(){
                // Se guarda el nuevo permiso en base de datos
                Axios.post(appsettings.Backend + this.UrlsRoles.AgregarPermisoARol, {...this.Rol,...this.Elemento})
                .then(response => {
                    // Se evalua respuesta del mismo
                    if (response.data.ErrorOperacion == 1 || response.data.ErrorInesperado == 1) {
                        this.CargarDatos();
                        this.MostrarPopUpB = true;
                        this.ActualizarPopUp("Agregado de Permiso",response.data.Data,true)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
            Alta(Permiso){
                console.log(this.Rol)
                // Se da de alta el rol en base de datos
                Axios.post(appsettings.Backend + this.UrlsRoles.DarDeAltaPermisoEnRol, { ...this.Rol, ...Permiso})
                .then(response => {
                    // Se evalua respuesta del mismo
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {
                            this.MostrarPopUpB = true;
                            this.ActualizarPopUp("Alta de Permiso",response.data.Data,true)
                        }
                        else {
                            // Se actualiza info de popup
                            this.MostrarPopUpB = true;
                            this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                        }
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
            Baja(Permiso){
                // Se da de baja el permiso en base de datos
                Axios.post(appsettings.Backend + this.UrlsRoles.DarDeBajaPermisoEnRol, {...this.Rol,...Permiso})
                .then(response => {
                    // Se evalua respuesta del mismo
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {
                            this.MostrarPopUpB = true;
                            this.ActualizarPopUp("Baja de Permiso",response.data.Data,true)
                        }
                        else {
                            // Se actualiza info de popup
                            this.MostrarPopUpB = true;
                            this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                        }
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
            Quitar(Permiso){
                // Se da de baja el rol en base de datos
                Axios.post(appsettings.Backend + this.UrlsRoles.QuitarPermisoARol, {...this.Rol,...Permiso})
                .then(response => {
                    // Se evalua respuesta del mismos
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {
                            this.MostrarPopUpB = true;
                            this.ActualizarPopUp("Eliminacion de Permiso",response.data.Data,true)
                        }
                        else {
                            // Se actualiza info de popup
                            this.MostrarPopUpB = true;
                            this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                        }
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                        return false;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            },
            VerDescripcion(Permiso){
                this.Descripcion = Permiso['descripcion']
                this.PermisoSeleccionado = Permiso['permiso']
            },

            Drag(event,Rol){
                this.Elemento = Rol;
                event.dataTransfer.setData("text/plain",event.target.id);
            },
            Drop(event){
                event.preventDefault();
                let idElemento = event.dataTransfer.getData("text/plain")
                let elemento = document.getElementById(idElemento)

                let div;
                div = elemento.childNodes[0].childNodes[1];
                div.style.visibility = 'visible';

                div = elemento.childNodes[0].childNodes[2];
                div.style.visibility = 'visible';

                this.Agregar();
                let contenedor = document.getElementById("contenedorDrag")

                contenedor.appendChild(elemento)
                //event.target.appendChild(elemento);
                event.dataTransfer.clearData();
            },
            AllowDrop(event){
                event.preventDefault();
            },
            Accion(Permiso,Accion){
                // Se muestra popup para que el usuario conforme la accion que realizara
                this.Permiso = Permiso;
                this.AccionRealizada = Accion;

                // Se actualiza info de popup
                this.ActualizarPopUp("Confirmacion","Esta seguro que desea realizar la accion?",false);
                this.MostrarPopUpA = true;
            },
            Aceptar(){
                // Se ejecuta accion a realizar por el usuario de acuerdo al boton elegido
                this.MostrarPopUpA = false;
                switch(this.AccionRealizada){
                    case 'Alta':    this.Alta(this.Permiso)
                                setTimeout(function() {
                                this.CargarDatos();
                                }.bind(this), 1000);
                                break;
                    case 'Baja':    this.Baja(this.Permiso);
                                    setTimeout(function() {
                                    this.CargarDatos();
                                    }.bind(this), 1000);
                                    break;
                    case 'Quitar':  this.Quitar(this.Permiso);
                                    setTimeout(function() {
                                    this.CargarDatos();
                                    }.bind(this), 1000);
                                    break;
                }
            },
            Cancelar(){
                // Se quita popup de pantalla en caso de que el usuario haya cancelado la accion
                this.MostrarPopUpA = false;
                this.MostrarPopUpB = false;
            },
            ActualizarPopUp(Titulo,Mensaje,Cerrar){
                // Se carga informacion al popup
                this.InfoPopUp = {
                    name: "PopUpComponent",
                    props: {
                        CierreHabilitado: Cerrar,
                        Titulo: Titulo,
                        Mensaje: Mensaje
                    }
                };
                this.$store.commit("setPropertiesRoute", this.InfoPopUp);
            },
            CerrarPopUp(){
                // Se cita el popup de pantalla cuando el cliente lo cierra
                this.MostrarPopUpA = false;
                this.MostrarPopUpB = false;
            },
        },
        components: {
            PopUpComponent
        }
            
        }
    </script>
    
    <style scoped>
    .global {
        height: 170px;
        overflow-y: scroll;
    }
    .modulo {
        height: 100vh;
        overflow-y: scroll;
    }
    #mensajes {
        height: auto;
    }
    .texto {
        padding:4px;
    }
    
    [draggable] {
        user-select: none;
    }
     
    .column:hover {
        border: 2px dotted #666666;
        background-color: #ccc;
        border-radius: 10px;
        box-shadow: inset 0 0 3px #000;
        cursor: move;
    }

    .tablestyle {
        font-size: 12px;  
        text-align:center; 
        width: auto; 
        vertical-align: middle;
    }
    .center {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-tooltip {
  position: relative;
}
.custom-tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -30px;
  transform: translateX(-50%);
}
.custom-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -6px;
  border-top: 6px solid #333;
  border-bottom: none;
}
.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
  visibility: visible;
  opacity: 1;
}

    </style>