<!-- Componente que sirve solamente para creacion o modificacion de objetos  -->

<template>
    
    <div class="p-5">
        <div class="col-lg-8 text-center">
            <h1 class="h4 text-gray-900 mb-4">{{ Titulo }}</h1>
            <span class="close-button" v-on:click="CerrarForm">X</span>
        </div>
        <form class="user">
            <div v-for="Input in DatosFormulario" :key="Input.Nombre" class="form-group">
                <div class="col-lg-8">
                    <label class="col-lg-12" :for="Input.Nombre">{{ Input.Etiqueta }}</label>
                    <input class="form-control form-control-user" :v-model="DatosFormulario[Input.Id]" :id="Input.Id" :type="Input.Tipo" :value="Input.Valor" :disabled="!Input.Habilitado">
                </div>
                
            </div>
            <div class="col-lg-8">
                <hr>
            </div>
            <div class="col-lg-8 center">
                <a class="col-lg-2 content btn btn-primary btn-user btn-block" @click="Aceptar">
                Aceptar
            </a>

            </div>
            
        </form>


        <PopUpComponent v-if="MostrarPopUp" @CerrarPopUp="CerrarPopUp()"></PopUpComponent> 

    </div>
</template>
  
<script>

    import { appsettings } from '../../../appsettings';
    import PopUpComponent from './PopUpComponent.vue';
    import Axios from 'axios'

    export default {
        // Props:
        //     Tipo --> Utilizada para saber si el formulario es de creacion (C) o modificacion (M)
        //     Modulo --> Utilizada para recuperar las Urls del modulo. Ej. Roles
        //     Clase --> Utilizada para mostrar el singular del modulo. Ej. Rol
        //     Inputs --> Json que tiene toda la informacion de cada input del formulario. Ej.
        //                Inputs:[
        //                         {
        //                             Id: String,
        //                             Etiqueta: String,
        //                             Tipo: String,
        //                             Habilitado: Boolean,
        //                         }
        //                       ]
        //                 Id --> id del input
        //                 Etiqueta --> Nombre del campo a mostrar
        //                 Tipo --> Tipo de input. Ej. text,date,time, etc
        //                 Habilitado --> Usado para permitir edicion en el campo
        //     Datos --> Utilizada para mostrar datos del objeto en caso de modificacion

        props: {
            TipoProp: {
                type: String,
                required: false
            },
            ModuloProp:  {
                type: String,
                required: false
            },
            ClaseProp: {
                type: String,
                required: false
            },
            InputsProp: {
                type: String,
                required: false,
            },
            InputsHijoProp: {
                type: String,
                required: false,
            },
            DatosProp: {
                type: String,
                required: false,
            }
        },
        components:{
            PopUpComponent
        },
        data() {
            return {
                Props: {},
                Tipo: '',
                Modulo: '',
                Clase: '',
                Inputs: '',
                InputsHijo:'',
                Datos: '',
                Titulo: '',
                Error: 0,
                DatosFormulario: {},
                MostrarPopUp: false,
                InfoPopUp:{
                    Titulo: '',
                    Mensaje: '',
                    Cerrar: false
                }
            };
        },
        created() {
            // Se traen propiedades del state en caso de ser redireccion del componente
            this.Props = this.$store.state.FormComponent;
            
            // Si se trata de una instancia las variables toman el valor de props que brinda el padre, caso contrario toma el de redireccion
            this.Tipo = this.TipoProp? this.TipoProp : ( this.Props? this.Props.Tipo : null);
            this.Modulo = this.ModuloProp? this.ModuloProp : ( this.Props? this.Props.Modulo : null);
            this.Clase = this.ClaseProp? this.ClaseProp : ( this.Props? this.Props.Clase : null);
            this.Inputs = this.InputsProp? this.InputsProp : ( this.Props? this.Props.Inputs : null);
            this.InputsHijo = this.IInputsHijoProp? this.InputsHijoProp : ( this.Props? this.Props.InputsHijo : null);
            this.Datos = this.DatosProp? this.DatosProp : ( this.Props? this.Props.Datos : null);
            console.log(this.Inputs)
            // Si aluguno de los parametros necesarios para construir el componente son nulos, no se lo renderiza
            if(!this.Tipo || !this.Modulo || !this.Clase || !this.Inputs){

                const payload = {
                    name: 'PageErrorComponent',
                    props: {
                        Codigo: '600',
                        Mensaje: 'FRONT_ERROR_REDIRECT',
                        Descripcion: 'Error de redireccion'
                    }
                }
                this.$store.commit('setPropertiesRoute',payload);
                this.$router.push({name:'error'})
            }
            // Segun el valor de los parametros se cargan datos para el formulario
            this.Titulo = this.Tipo == "C" ? ("Nuevo/a " + this.Clase) : (this.Tipo == "M" ? ("Modificar " + this.Clase) : null);
            this.DatosFormulario = this.Inputs? JSON.parse(this.Inputs) : null;

            if (this.Datos && this.DatosFormulario) {
                const datosDeserializados = JSON.parse(this.Datos);
                this.DatosFormulario.forEach(input => {
                        const key = input.Id
                        if (key in datosDeserializados) {
                            input["Valor"] = datosDeserializados[key];
                        }
                        else {
                            input["Valor"] = "";
                        }
                });
            }
        },
        methods: {
            // Metodo que se encarga de enviar la peticion post del formulario al backend
            async Aceptar() {
                // Se arma payload para solicitud HTTP al backend
                let payload = {};
                this.DatosFormulario.forEach(dato => {
                    payload[dato.Id] = document.getElementById(dato.Id).value;
                });
                console.log(payload)
                let url;

                // Se busca la url del backend donde se envian los datos del formulario
                appsettings.UrlsBackend.forEach(modulo => {
                    if(this.Modulo in modulo){
                        if(this.Tipo=='C'){
                            url = modulo[this.Modulo].Crear;
                        }
                        else{
                            url = modulo[this.Modulo].Modificar;
                        }
                        
                    }
                });

                try{
                    // Se envia peticion a backend
                    const respuesta = await Axios.post(appsettings.Backend+url,payload);
                
                    // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                    if (respuesta.data.ErrorOperacion == 0) {
                            if (respuesta.data.ErrorInesperado == 0) {
                                
                                // Se actualiza info de popup
                                this.ActualizarPopUp((this.Tipo=='C'? 'Creacion ' : 'Modificacion ') + 'de ' + this.Modulo,respuesta.data.Data,true);
                                
                                this.DatosFormulario.forEach(input => {
                                        const key = input.Id
                                        if (key in payload) {
                                            input["Valor"] = payload[key];
                                        }
                                        else {
                                            input["Valor"] = "";
                                        }
                                });
                            }
                            else{
                                // Se actualiza info de popup
                                this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                            }
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Operacion",respuesta.data.Data,true);
                    }   
                }
                catch(error){
                    console.log(error);
                    // Se actualiza info de popup
                    this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                }

                // Se muestra popup en pantalla
                this.MostrarPopUp = true;
            
            },
            CerrarForm(){
                this.$router.go(-1);
            },
            ActualizarPopUp(Titulo,Mensaje,Cerrar){
                this.InfoPopUp = {
                    name: "PopUpComponent",
                    props: {
                        CierreHabilitado: Cerrar,
                        Titulo: Titulo,
                        Mensaje: Mensaje
                    }
                };
                this.$store.commit("setPropertiesRoute", this.InfoPopUp);
            },
            CerrarPopUp(){
                this.MostrarPopUp = false;  
            }
        }
        
    };
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
}

.input {
  flex: 1;
  margin-right: 10px; /* Espacio entre el input y el botón */
}

.center {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
}

.disabled {
  opacity: 0.5; /* Reduce la opacidad del botón */
  pointer-events: none; /* Desactiva los eventos de puntero para el botón */
  cursor: not-allowed; /* Cambia el cursor a "no permitido" */
}

</style>