import { createRouter, createWebHistory } from 'vue-router'

import LoginComponent from './components/LoginComponent'
import FormComponent from './components/commons/FormComponent'
import PageErrorComponent from './components/commons/PageErrorComponent'
import HomeComponent from './components/HomeComponent'
import UsuariosComponent from './components/UsuariosComponent'
import PermisosComponent from './components/PermisosComponent'
import RolesComponent from './components/RolesComponent'
import LaboratoriosComponent from './components/LaboratoriosComponent'
import ExperienciasComponent from './components/ExperienciasComponent'
import DisponibilidadLaboratoriosComponent from './components/DisponibilidadLaboratoriosComponent'
import AsignacionRolesComponent from './components/AsignacionRolesComponent'
import AsignacionPermisosComponent from './components/AsignacionPermisosComponent'
import LTIComponent from './components/LTIComponent'
import InicioComponent from './components/InicioComponent'
import OtrosTurnosComponent from './components/OtrosTurnosComponent'
import MisTurnosComponent from './components/MisTurnosComponent'
import SolicitarTurnoComponent from './components/SolicitarTurnoComponent'
import SolicitarTurnoTComponent from './components/SolicitarTurnoTComponent'
import ReprogramarTurnoComponent from './components/ReprogramarTurnoComponent'
import CalendarioComponent from './components/CalendarioComponent'
import FormCalendarioComponent from './components/FormCalendarioComponent'
import MiPerfilComponent from './components/MiPerfilComponent'
import ValidacionComponent from './components/ValidacionComponent'
import HardwaresComponent from './components/HardwaresComponent'
import UniversidadesComponent from './components/UniversidadesComponent'

import { getDataStorage } from './utils/StorageUtils'


const routes = [
  {
    path: '/',
    name: 'inicio',
    component: LoginComponent
  },
  {
    path: '/:data',
    name: 'moodle',
    component: LoginComponent,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'main',
        name: 'main',
        component: InicioComponent
      },
      {
        path: 'lti',
        name: 'lti',
        component: LTIComponent
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        component: UsuariosComponent
      },
      {
        path: 'permisos',
        name: 'permisos',
        component: PermisosComponent   
      },
      {
        path: 'roles',
        name: 'roles',
        component: RolesComponent   
      },
      {
        path: 'asignacionroles',
        name: 'asignacionroles',
        component: AsignacionRolesComponent   
      },
      {
        path: 'asignacionpermisos',
        name: 'asignacionpermisos',
        component: AsignacionPermisosComponent   
      },     
      {
        path: 'form',
        name: 'form',
        component: FormComponent,
      },
      {
        path: 'disponibilidad',
        name: 'disponibilidad',
        component: DisponibilidadLaboratoriosComponent,
      },
      {
        path: 'laboratorios',
        name: 'laboratorios',
        component: LaboratoriosComponent   
      },
      {
        path: 'experiencias',
        name: 'experiencias',
        component: ExperienciasComponent   
      },
      {
        path: 'hardwares',
        name: 'hardwares',
        component: HardwaresComponent   
      },
      {
        path: 'universidades',
        name: 'universidades',
        component: UniversidadesComponent   
      },
      {
        path: 'turnos',
        name: 'turnos',
        component: OtrosTurnosComponent   
      },
      {
        path: 'misturnos',
        name: 'misturnos',
        component: MisTurnosComponent   
      },
      {
        path: 'solicitarturno',
        name: 'solicitarturno',
        component: SolicitarTurnoComponent   
      },
      {
        path: 'solicitarturnot',
        name: 'solicitarturnot',
        component: SolicitarTurnoTComponent   
      },
      {
        path: 'reprogramarturno',
        name: 'reprogramarturno',
        component: ReprogramarTurnoComponent   
      },
      {
        path: 'calendario',
        name: 'calendario',
        component: CalendarioComponent   
      },
      {
        path: 'crearcalendario',
        name: 'crearcalendario',
        component: FormCalendarioComponent   
      },
      {
        path: 'miperfil',
        name: 'miperfil',
        component: MiPerfilComponent   
      }
    ]
  },
  {
    path: '/completarinfo',
    name: 'completarinfo',
    component: ValidacionComponent,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: PageErrorComponent,
  },
]

const Router = createRouter({
    history: createWebHistory(),
    routes
  })

Router.beforeEach((to, from, next) => {

  // Para cada ruta solicitada se evalua si existe un token, si no hay se redirecciona a pagina de inicio
  let token = getDataStorage("token")
  
    if(!token && to.name !== 'inicio'){
      next('/');
    }
    else{
      next();
    }
});

export default Router