<!-- Componente que se utiliza para la gestion del modulo Hardwares -->
<template>
    <div v-if="PermisosModulo.Crear" class="col-sm-12 col-md-6" style="margin-bottom: 20px;">
        <div class="dataTables_length" id="dataTable_length">
            <a class="btn btn-primary btn-icon-split" v-on:click="Crear">
                <span class="icon text-white-50">
                    <i class="fas fa-arrow-right"></i>
                </span>
                <span class="text" style="width: 150px; text-align: left;">Crear Hardware</span>
            </a>
        </div>
        
    </div>

    <div>
        <div id="wrapper">
            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="container-fluid">
                        <div class="card shadow mb-4">
                            <div class="card-header py-3">
                                <h6 class="m-0 font-weight-bold text-primary">Hardwares</h6>
                            </div>
                            <div class="card-body">  
                                <div class="table-responsive">
                                    <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                            <!-- Espacio para gregar algo a tabla -->
                                            </div>
                                            <div class="col-sm-12 col-md-6">
                                                <div id="dataTable_filter" class="dataTables_filter">
                                                    <label >
                                                        Buscar
                                                        <input v-on:input="Buscar" id="Buscar" type="search" class="form-control form-control-sm" placeholder="" aria-controls="dataTable" style="width: 186px; height: 30px;">
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <table class="table table-bordered dataTable no-footer" id="dataTable" width="100%" cellspacing="0" role="grid" aria-describedby="dataTable_info" style="width: 100%;">
                                                    <thead>
                                                        <tr >
                                                            <th class="tablestyle">ID</th>
                                                            <th class="tablestyle">Host</th>
                                                            <th class="tablestyle">Descripcion</th>
                                                            <th class="tablestyle">Estado</th>                                                        
                                                            <th class="tablestyle" v-if="PermisosModulo.Modificar || PermisosModulo.Borrar || PermisosModulo.Alta || PermisosModulo.Baja">Acciones</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                
                                                        <tr v-for="Fila in Filas" :key='Fila'>
                                                            <td class="tablestyle"  style="height: 50px;" id="idHardware" colspan="1">{{ Fila['idHardware'] }} </td>
                                                            <td class="tablestyle"  style="height: 50px;" id="host" colspan="1">{{ Fila['host'] }} </td>
                                                            <td class="tablestyle" style="height: 50px;" id="descripcion" colspan="1">{{ Fila['descripcion'] }} </td>
                                                            <td class="tablestyle" style="height: 50px;" id="estado">{{ Fila['estado'] }} </td>
                                                            <td v-if="PermisosModulo.Modificar || PermisosModulo.Borrar || PermisosModulo.Alta || PermisosModulo.Baja" class="tablestyle" style="width: 160px;">
                                                                <div class="d-flex">
                                                                    <a v-if="PermisosModulo.Modificar" class="btn btn-info btn-circle custom-tooltip" data-tooltip="Modificar" style="background-color: #4e73df; border: none;" v-on:click="Modificar(Fila)">
                                                                        <i class="fas fa-edit"></i>
                                                                    </a>
                                                                    <a v-if="PermisosModulo.Alta" class="btn btn-success btn-circle custom-tooltip" data-tooltip="Dar Alta" v-on:click="Accion(Fila,'Alta')">
                                                                        <i class="fas fa-check"></i>
                                                                    </a>
                                                                    <a v-if="PermisosModulo.Baja" class="btn btn-warning btn-circle custom-tooltip" data-tooltip="Dar baja" v-on:click="Accion(Fila,'Baja')">
                                                                        <i class="fas fa-exclamation-triangle"></i>
                                                                    </a>
                                                                    <a v-if="PermisosModulo.Borrar" class="btn btn-danger btn-circle custom-tooltip" data-tooltip="Eliminar" v-on:click="Accion(Fila,'Borrar')">
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-5">
                                                <div class="dataTables_info" id="dataTable_info" role="status" aria-live="polite">{{ CantFilas }} de {{ CantFilas }}</div>
                                            </div>
                                            <div class="col-sm-12 col-md-7">
                                                <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                                    <ul class="pagination">
                                                        <li class="paginate_button page-item previous enabled" id="dataTable_previous">
                                                            <a v-on:click="VolverPagina" id="Anterior" aria-controls="dataTable" data-dt-idx="0" tabindex="0" class="page-link">Anterior</a>
                                                        </li>
                                                        <li class="paginate_button page-item active">
                                                            <a  aria-controls="dataTable" data-dt-idx="1" tabindex="0" class="page-link">{{ this.NumeroPagina+1 }}</a>
                                                        </li>
                                                        <li class="paginate_button page-item next enabled" id="dataTable_next">
                                                            <a v-on:click="PasarPagina" id="Posterior" aria-controls="dataTable" data-dt-idx="2" tabindex="0" class="page-link">Posterior</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  

        <PopUpComponent v-if="MostrarPopUpA" @CerrarPopUp="CerrarPopUp()">
            <div class="col-lg-12 center">
                <a class="col-lg-8 content btn btn-primary btn-user btn-block" v-on:click="Aceptar">
                    Aceptar
                </a>
            </div>
            <div class="col-lg-12 center">
                <a class="col-lg-8 content btn btn-primary btn-user btn-block" v-on:click="Cancelar()">
                    Cancelar
                </a>
            </div>
        </PopUpComponent> 

        <PopUpComponent v-if="MostrarPopUpB" @CerrarPopUp="CerrarPopUp()"></PopUpComponent> 

    </div>



</template>
  
<script>
    import Axios from 'axios';
    import { appsettings } from '../../appsettings';
    import PopUpComponent from './commons/PopUpComponent.vue';
    import { getDataStorage } from '../utils/StorageUtils';

    export default {
    data() {
        return {
            PermisosModulo:{},
            Filas: [],
            CantFilas: 0,
            NumeroPagina: 0,
            Urls: {},
            DatosFila: {},
            AccionRealizada: '',
            MostrarPopUpA: false,
            MostrarPopUpB: false,
            MostrarPopUpC: false,
            Inputs: "[{\"Id\":\"idHardware\",\"Etiqueta\":\"ID\",\"Tipo\":\"text\",\"Habilitado\":false},{\"Id\":\"host\",\"Etiqueta\":\"Host\",\"Tipo\":\"text\",\"Habilitado\":true},{\"Id\":\"descripcion\",\"Etiqueta\":\"Descripcion\",\"Tipo\":\"text\",\"Habilitado\":true},{\"Id\":\"estado\",\"Etiqueta\":\"Estado\",\"Tipo\":\"text\",\"Habilitado\":false}]",
            InfoPopUp:{
                Titulo: '',
                Mensaje: '',
                Cerrar: false
            },
            Hardware: null,
        };
    },
    created() {
        // Se traen los permisos para el modulo
        const permisos = JSON.parse(getDataStorage('permisos'));
        this.PermisosModulo = permisos.Hardwares;

        // Se traen urls del modulo a gestionar
        appsettings.UrlsBackend.forEach(modulo => {
            if ("Hardwares" in modulo) {
                this.Urls = modulo["Hardwares"];
            }
        });

        // Al crear el componente se carga con los datos del primer bloque (Paginacion en base de datos)
        this.Listar(this.NumeroPagina);
    },
    methods: {
        Listar(Bloque) {
            // Se arma payload de la solicitud y se listan los permisos
            const payload = {
                "bloque": Bloque
            };
            // Se envia peticion a backend
            Axios.post(appsettings.Backend + this.Urls.ListarPaginado, payload)
            .then(response => {
                // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                if (response.data.ErrorOperacion == 0) {
                    if (response.data.ErrorInesperado == 0) {
                        
                        this.Filas = response.data.Data;
                        if (this.Filas) {
                            this.CantFilas = this.Filas.length;
                        }else{
                            this.CantFilas = 0;
                        }

                        console.log(this.Filas)
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        Buscar() {
            // Se realiza la peticion de busqueda de permisos a partir de tres caracteres
            let Cadena = document.getElementById("Buscar").value;
            if (Cadena == 0) {
                this.Listar(0);
            }
            if (Cadena.length >= 3) {
                // Se arma payload de la solicitud http
                const payload = {
                    "cadena": Cadena
                };
                // Se envia peticion a backend
                Axios.post(appsettings.Backend + this.Urls.Buscar, payload)
                .then(response => {
                    // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                    if (response.data.ErrorOperacion == 0) {
                        if (response.data.ErrorInesperado == 0) {
                            this.Filas = response.data.Data;
                            if (this.Filas) {
                                this.CantFilas = this.Filas.length;
                            }
                            else{
                                this.CantFilas = 0;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
        },
        Crear(){
            // Se redirige a formulario de creacion
            const payloadForm = {
                name: "FormComponent",
                props: {
                    Tipo: "C",
                    Modulo: "Hardwares",
                    Clase: "Hardware",
                    Inputs: this.Inputs
                }
            };
            this.$store.commit("setPropertiesRoute", payloadForm);
            this.$router.push({ name: "form" });
            
        },
        Modificar(Fila) {
            // Se redirige a formulario de modificacion
            const payloadForm = {
                name: "FormComponent",
                props: {
                    Tipo: "M",
                    Modulo: "Hardwares",
                    Clase: "Hardware",
                    Inputs: this.Inputs,
                    Datos: JSON.stringify(Fila)
                }
            };
            this.$store.commit("setPropertiesRoute", payloadForm);
            this.$router.push({ name: "form" });
        },
        Alta(Fila) {

            // Se envia peticion a backend para el alta
            Axios.post(appsettings.Backend + this.Urls.DarDeAlta, Fila)
                .then(response => {
                // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                if (response.data.ErrorOperacion == 0) {
                    if (response.data.ErrorInesperado == 0) {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Alta de Hardware",response.data.Data,true);
                        return false;
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                        return false;
                    }
                }
                else {
                    // Se actualiza info de popup
                    this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                    return false;
                }
            })
            .then(response => {
                if (!response) {
                    // Se muestra popup en pantalla
                    this.MostrarPopUpB = true;
                }
            })
            .catch(error => {
                console.log(error);
                // Se actualiza info de popup
                this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                // Se muestra popup en pantalla
                this.MostrarPopUpB = true;
            });
        },
        Baja(Fila) {
            // Se envia peticion a backend para la baja
            Axios.post(appsettings.Backend + this.Urls.DarDeBaja, Fila)
                .then(response => {
                // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                if (response.data.ErrorOperacion == 0) {
                    if (response.data.ErrorInesperado == 0) {

                        // Se actualiza info de popup
                        this.ActualizarPopUp("Baja de Hardware",response.data.Data,true);
                        return false;
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                        return false;
                    }
                }
                else {
                    // Se actualiza info de popup
                    this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                    return false;
                }
            })
            .then(response => {
                if (!response) {
                    // Se muestra popup en pantalla
                    this.MostrarPopUpB = true;
                }
            })
            .catch(error => {
                console.log(error);
                // Se actualiza info de popup
                this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                // Se muestra popup en pantalla
                this.MostrarPopUpB = true;
            });
        },
        Borrar(Fila) {
            // Se envia peticion a backend para eliminacion
            Axios.post(appsettings.Backend + this.Urls.Borrar, Fila)
                .then(response => {
                // Se evalua respuesta del mismo y se carga informacion a mostrar en popup
                if (response.data.ErrorOperacion == 0) {
                    if (response.data.ErrorInesperado == 0) {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Eliminacion de Hardware",response.data.Data,true);
                        return false;
                    }
                    else {
                        // Se actualiza info de popup
                        this.ActualizarPopUp("Error de Servidor (BACK)","El servidor encontró un error interno. Por favor, contacta al administrador del sistema",true);
                        return false;
                    }
                }
                else {
                    // Se actualiza info de popup
                    this.ActualizarPopUp("Error de Operacion",response.data.Data,true);
                    return false;
                }
            })
            .then(response => {
                if (!response) {
                    // Se muestra popup en pantalla
                    this.MostrarPopUpB = true;
                }
            })
            .catch(error => {
                console.log(error);
                // Se actualiza info de popup
                this.ActualizarPopUp("Error de Peticion","La solicitud no pudo ser procesada. Por favor, verifica los datos y vuelve a intentarlo",true);
                // Se muestra popup en pantalla
                this.MostrarPopUpB = true;
            });
        },
        PasarPagina() {
            // Se pide al backend el siguiente bloque de datos 
            this.Listar(this.NumeroPagina + 1);
            setTimeout(function() {
                if (this.CantFilas < 1) {
                    this.Listar(this.NumeroPagina);
                }
                else {
                    this.NumeroPagina = this.NumeroPagina + 1;
                }
            }.bind(this), 300);
        },
        VolverPagina() {
            // Se pide al backend el anterior bloque de datos 
            if (this.NumeroPagina != 0) {
                this.Listar(this.NumeroPagina - 1);
                setTimeout(function() {
                    if (this.CantFilas < 1) {
                        this.Listar(this.NumeroPagina);
                    }
                    else {
                        this.NumeroPagina = this.NumeroPagina - 1;
                    }
                }.bind(this), 300);
            }
        },
        Accion(Fila,Accion){
            // Se muestra popup para que el usuario conforme la accion que realizara
            this.DatosFila = Fila;
            this.AccionRealizada = Accion;

            // Se actualiza info de popup
            this.ActualizarPopUp("Confirmacion","Esta seguro que desea realizar la accion?",false);
            this.MostrarPopUpA = true;
        },
        Aceptar(){
            // Se ejecuta accion a realizar por el usuario de acuerdo al boton elegido
            this.MostrarPopUpA = false;
            switch(this.AccionRealizada){
                case 'Alta':    this.Alta(this.DatosFila)
                                setTimeout(function() {
                                this.Listar(0);
                                }.bind(this), 1000);
                                break;
                case 'Baja':    this.Baja(this.DatosFila);
                                setTimeout(function() {
                                this.Listar(0);
                                }.bind(this), 1000);
                                break;
                case 'Borrar':  this.Borrar(this.DatosFila);
                                setTimeout(function() {
                                this.Listar(0);
                                }.bind(this), 1000);
                                break;
            }
        },
        Cancelar(){
            // Se quita popup de pantalla en caso de que el usuario haya cancelado la accion
            this.MostrarPopUpA = false;
            this.MostrarPopUpB = false;
        },
        ActualizarPopUp(Titulo,Mensaje,Cerrar){
            // Se carga informacion al popup
            this.InfoPopUp = {
                name: "PopUpComponent",
                props: {
                    CierreHabilitado: Cerrar,
                    Titulo: Titulo,
                    Mensaje: Mensaje
                }
            };
            this.$store.commit("setPropertiesRoute", this.InfoPopUp);
        },
        CerrarPopUp(){
            // Se cita el popup de pantalla cuando el cliente lo cierra
            this.MostrarPopUpA = false;
            this.MostrarPopUpB = false;
            this.MostrarPopUpC = false;
        },
        ValidarIP(){
            const input = document.getElementById('ip');
            const ip = input.value.trim();
            
            // Expresión regular para validar una dirección IP
            const regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
            
            if (regex.test(ip)) {
                input.classList.remove('invalid');
                input.classList.add('valid');
            } else {
                input.classList.remove('valid');
                input.classList.add('invalid');
            }
        }
    },
    components:{
        PopUpComponent
    }
}

</script>

<style scoped>

.tablestyle {
    font-size: 12px;  
    text-align:center; 
    width: auto; 
    vertical-align: middle;
}
.center {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-tooltip {
  position: relative;
  margin-inline: 5px;
}
.custom-tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -30px;
  transform: translateX(-50%);
}
.custom-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -6px;
  border-top: 6px solid #333;
  border-bottom: none;
}
.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
  visibility: visible;
  opacity: 1;
}
.scroll {
  min-height: 200px;
  max-height: 250px; 
  overflow-y: auto;
}

.ip-input {
  border: 1px solid;
}

.ip-input.invalid {
  border-color: red;
}

.ip-input.valid {
  border-color: green;
}
  .autocomplete-container {
      position: relative;
      display: inline-block;
    }

    input[type="text"] {
      width: 200px;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .autocomplete-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      min-width: 400px;
      max-height: 100px;
      overflow-y: auto;
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      display: none;
    }

    .autocomplete-dropdown-item {
      padding: 5px;
      cursor: pointer;
    }

    .autocomplete-dropdown-item:hover {
      background-color: #f0f0f0;
    }

    .toggle-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
</style>