<template>

    <!-- Contenedor principal -->
    <div id="wrapper">
        <!-- Barra lateral -->
        <div id="wrapper">
            <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                <a class="sidebar-brand d-flex align-items-center justify-content-center">
                    <div class="sidebar-brand-text mx-3">LABREM</div>
                </a>

                <hr class="sidebar-divider my-0">

                <li class="nav-item">
                    <a class="nav-link" @click="Redirecionar('/home/main')">
                        <i class="fas fa-fw fa-tachometer-alt"></i>
                        <span>Inicio</span>
                    </a>
                </li>
                <div v-if="Object.keys(Permisos.LTI).length>0 || Object.keys(Permisos.Permisos).length>0 || Object.keys(Permisos.Roles).length>0 || Object.keys(Permisos.Usuarios).length>0 || (Object.keys(Permisos.Experiencias).length>0 && !Permisos.Experiencias.Ver) || Object.keys(Permisos.Laboratorios).length>0">
                    <hr class="sidebar-divider">

                    <div class="sidebar-heading">
                        Gestion
                    </div>

                </div>

                <li class="nav-item active" v-if="Object.keys(Permisos.LTI).length>0 || Object.keys(Permisos.Permisos).length>0 || Object.keys(Permisos.Roles).length>0 || Object.keys(Permisos.Usuarios).length>0 || (Object.keys(Permisos.Experiencias).length>0 && !Permisos.Experiencias.Ver) || Object.keys(Permisos.Laboratorios).length>0">
                    <a class="nav-link" data-toggle="collapse" data-target="#collapseUtilities1"
                        aria-expanded="true" aria-controls="collapseUtilities">
                        <i class="fas fa-fw fa-wrench"></i>
                        <span>Gestion</span>
                    </a> 
                    <div id="collapseUtilities1" class="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div class="bg-white py-2 collapse-inner rounded">
                            <a v-if="Object.keys(Permisos.Calendario).length>0" class="collapse-item" @click="Redirecionar('/home/calendario')">Calendario</a>
                            <a v-if="Object.keys(Permisos.Experiencias).length>0" class="collapse-item" @click="Redirecionar('/home/experiencias')">Experiencias</a>
                            <a v-if="Object.keys(Permisos.Experiencias).length>0" class="collapse-item" @click="Redirecionar('/home/hardwares')">Hardwares</a>
                            <a v-if="Object.keys(Permisos.Laboratorios).length>0" class="collapse-item" @click="Redirecionar('/home/laboratorios')">Laboratorios</a>
                            <a v-if="Object.keys(Permisos.Permisos).length>0" class="collapse-item"  @click="Redirecionar('/home/permisos')">Permisos</a>
                            <a v-if="Object.keys(Permisos.Roles).length>0" class="collapse-item"  @click="Redirecionar('/home/roles')" >Roles</a>
                            <a class="collapse-item"  @click="Redirecionar('/home/universidades')" >Universidades</a>
                            <a v-if="Object.keys(Permisos.Usuarios).length>0" class="collapse-item"  @click="Redirecionar('/home/usuarios')" >Usuarios</a>

                        </div>
                    </div>
                        
                </li>

                <div v-if="Object.keys(Permisos.Turnos).length>0">
                    <hr class="sidebar-divider">

                    <div class="sidebar-heading">
                        Turnos
                    </div>
                </div>
                <li v-if="Object.keys(Permisos.Turnos).length>0" class="nav-item active">
                    <a class="nav-link" data-toggle="collapse" data-target="#collapseUtilities2"
                        aria-expanded="true" aria-controls="collapseUtilities">
                        <i class="fas fa-fw fa-folder"></i>
                        <span>Turnos</span>
                    </a>
                    <div id="collapseUtilities2" class="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div class="bg-white py-2 collapse-inner rounded">
                            <a v-if="Permisos.Turnos.SolicitarPropio" class="collapse-item" @click="Redirecionar('/home/solicitarturno')">Solicitar Turno</a>
                            <a v-if="Permisos.Turnos.SolicitarTercero" class="collapse-item" @click="Redirecionar('/home/solicitarturnot')">Asignar Turno</a>
                            <a v-if="Permisos.Turnos.VerPropio" class="collapse-item"  @click="Redirecionar('/home/misturnos')">Mis Turnos</a>
                            <a v-if="Permisos.Turnos.VerTercero" class="collapse-item"  @click="Redirecionar('/home/turnos')" >Otros Turnos</a>
                        </div>
                    </div>
                </li>
                <div v-if="Object.keys(Permisos.Estadisticas).length>0">
                    <hr class="sidebar-divider">

                    <div class="sidebar-heading">
                        Reportes
                    </div>
                </div>
                <li v-if="Object.keys(Permisos.Estadisticas).length>0" class="nav-item active">
                    <a class="nav-link" data-toggle="collapse" data-target="#collapseUtilities3"
                        aria-expanded="true" aria-controls="collapseTwo">
                        <i class="fas fa-fw fa-chart-area"></i>
                        <span>Estadisticas</span>
                    </a>
                    <div id="collapseUtilities3" class="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div class="bg-white py-2 collapse-inner rounded">
                            <a v-if="Permisos.Estadisticas.SolicitarPropio" class="collapse-item" >Mis Estadisticas</a>
                            <a v-if="Permisos.Estadisticas.SolicitarTercero" class="collapse-item" >Otros Reportes</a>
                        </div>
                    </div>
                </li>

                <!-- Divider -->
                <hr class="sidebar-divider d-none d-md-block">
            </ul>
        </div>

        <!-- Contenedor -->
        <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
                <!-- Barra de Navegacion -->
                <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    <div class="d-sm-flex align-items-center justify-content-center mb-12">
                        <h1 class="h3 mb-0 text-gray-800">Laboratorios Remotos - UNT</h1>
                    </div>
                    <ul class="navbar-nav ml-auto">

                        <li class="nav-item dropdown no-arrow">
                            
                            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="mr-2 d-none d-lg-inline text-gray-800 small">{{ NombreUsuario }}</span>
                                <img class="img-profile rounded-circle"
                                    src="../../public/assets/img/undraw_profile_1.svg">
                            </a>
                            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="userDropdown">
                                <a class="dropdown-item" @click="Redirecionar('/home/miperfil')">
                                    <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Mi perfil
                                </a>
                                <div class="dropdown-divider"></div>
                                <a v-on:click="LogOut" class="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                    <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Salir
                                </a>
                            </div>
                        </li>
                    </ul>
                </nav>

                <!-- Contendor de modulos de gestion-->
                <div class="container-fluid">
                    <RouterView></RouterView>
                </div>

            </div> 
        </div>
    </div>

</template>
  
<script>
    import { getDataStorage } from '../utils/StorageUtils';

    export default {

        data(){
            return{
                Permisos: {},
                NombreUsuario: null,
            }
        },
        created(){
            // Se busca el nombre del usuario en el sesion storage
            const usuario = JSON.parse(getDataStorage('usuario'));
            this.NombreUsuario = !(usuario.apellido + ' ' + usuario.nombre) ? 'Sin Nombre' : usuario.apellido + ' ' + usuario.nombre;

            // Se buscan los permisos para ese componente
            this.Permisos = JSON.parse(getDataStorage('permisos'));
        },
        methods:{
            Redirecionar(ruta){
                this.$router.push({path:ruta})
            },
            LogOut(){
                sessionStorage.clear();
                this.$router.push({name:'inicio'});
            }
        }
    }
</script>