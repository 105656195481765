import { createApp } from 'vue'
import { Store } from './utils/StoreUtils'
import Router from './Router'
import App from './App.vue'
import Axios from 'axios'
import { getDataStorage } from './utils/StorageUtils'

const app = createApp(App)
app.use(Router)
app.use(Store)

// Se agrega un interceptor para las request HTTP salientes
Axios.interceptors.request.use(async (config) => {
        let token;

        try{
            token = JSON.parse(getDataStorage('token'));
        }
        catch(Exception){
            token = getDataStorage('token');
        }

        // Se agrega token a header 
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        
        return config;
    }, 
    (error) => {
        return Promise.reject(error);
    }
);

app.mount('#app')