<template>
    <div class="d-sm-flex align-items-center justify-content-center mb-12">
        <h1 class="h3 mb-0 text-gray-800 my-3">{{ JSON.parse(this.Laboratorio)['nombre'] }}</h1>
    </div>
    <div class="row">
        <div v-for="Dia in Dias" :key="Dia" class="col-lg-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3 contenedor-flexbox">
                    <h6 class="m-0 font-weight-bold text-primary" style="text-align: center;"> {{ Dia }} </h6>
                </div>
                <div class="card-body" style="min-height: 450px; max-height: 450px;">
                    <div class="contenedor-flexbox scroll">
                        <table class="table table-bordered dataTable no-footer" id="dataTable" width="100%" cellspacing="0" role="grid" aria-describedby="dataTable_info" style="width: 100%;">
                            <thead>
                                <tr >
                                    <th class="tablestyle">Inicio</th>
                                    <th class="tablestyle">Fin</th>                                                      
                                    <th class="tablestyle">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="Fila in Disponibilidad[Dia]" :key='Fila'>
                                    <td class="tablestyle"  style="width: 10px;" id="horaInicio">{{ Fila['inicio'] }} </td>
                                    <td class="tablestyle" style="width: 5px;" id="horaFin" >{{ Fila['fin'] }} </td>
                                    <td class="tablestyle" style="width: 80px;">
                                        <div class="d-flex">
                                            <a  class="btn btn-danger btn-circle custom-tooltip" data-tooltip="Eliminar" v-on:click="Borrar(Fila,Dia)">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <hr class="sidebar-divider">

                    <div class="contenedor-flexbox" >
                        <div class="d-flex align-items-center justify-content-center">
                            <label>Agregar Disponibilidad</label>

                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="form-group text-center">
                                <!-- <label for="input1">Título 1</label> -->
                                <input v-on:input="Inicio(Dia)" type="time" class="form-control custom-tooltip" data-tooltip="Hora Inicio" :id="'inicio'+Dia">
                            </div>
                            <div class="form-group text-center">
                                <!-- <label for="input2">Título 2</label> -->
                                <input v-on:input="Fin(Dia)" type="time" class="form-control custom-tooltip" data-tooltip="Hora Fin" :id="'fin'+Dia" disabled="true">
                            </div>
                            <div class="form-group text-center">
                                <a  class="btn btn-danger btn-circle custom-tooltip" data-tooltip="Agregar" style="background-color: #90cf46; border: none;" v-on:click="Agregar(Dia)">
                                    <i class="fas fa-plus"></i>
                                </a>
                            </div>
                                            
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>

</template>

<script>
    import { appsettings } from '../../appsettings';
    import { getDataStorage } from '../utils/StorageUtils';

    import Axios from 'axios';

    export default {
        data(){
            return{
                Laboratorio: {},
                PermisosModulo: {},
                Dias: ['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'],
                Disponibilidad: {
                    Lunes:[{inicio:'08:00',fin:'12:00'},{inicio:'14:00',fin:'20:00'},{inicio:'14:00',fin:'20:00'}],
                    Martes: [],
                    Miercoles:[],
                    Jueves:[],
                    Viernes:[],
                }
            }
        },
        created(){
            // Se traen los permisos para el modulo
            const permisos = JSON.parse(getDataStorage('permisos'));
            this.PermisosModulo = permisos.Experiencias;

            //Se recupera el laboratorio a cambiar la disponibilidad
            this.Laboratorio = this.$store.state.Laboratorio;
            this.Disponibilidad = JSON.parse(this.Laboratorio)['disponibilidad'];

            // Se traen urls del modulo a gestionar
            appsettings.UrlsBackend.forEach(modulo => {
                if ("Laboratorios" in modulo) {
                    this.Urls = modulo["Laboratorios"];
                }
            });
        },
        methods:{
            Inicio(Dia){
                const inicio = document.getElementById('inicio'+Dia);
                const fin = document.getElementById('fin'+Dia);

                if (inicio.value.length === 5 && inicio.value.includes(':')) {
                    fin.disabled = false;
                }
                else{
                    fin.value = '';
                    fin.disabled = true;
                }
            },
            Fin(Dia){
                const inicio = document.getElementById('inicio'+Dia).value;
                const fin = document.getElementById('fin'+Dia).value;

                const horaI = inicio.split(':');
                const horaF = fin.split(':');

                const horaInicio = new Date(0,0,0,horaI[0],horaI[1])
                const horaFin = new Date(0,0,0,horaF[0],horaF[1])

                if (horaFin<=horaInicio) {
                    document.getElementById('fin'+Dia).value = '';
                }

            },
            Agregar(Dia){
                // Se obtienen los valores de las entradas
                const horaInicio = document.getElementById('inicio'+Dia).value;
                const horaFin = document.getElementById('fin'+Dia).value;
                
                if (horaInicio.length === 5 && horaInicio.includes(':')) {
                    if (horaFin.length === 5 && horaFin.includes(':')) {
                        this.Disponibilidad[Dia].push({inicio: horaInicio,fin:horaFin});
                        this.Guardar();
                        document.getElementById('inicio'+Dia).value = '';
                        document.getElementById('fin'+Dia).value = '';
                        document.getElementById('fin'+Dia).disabled = true;
                    }
                }else{
                    document.getElementById('inicio'+Dia).value = '';
                    document.getElementById('fin'+Dia).value = '';
                }
                
            },
            Borrar(Fila,Dia){
                // Se quita el elemento de la tabla

                // Se busca el índice del objeto a borrar
                const index = this.Disponibilidad[Dia].findIndex(obj => obj.inicio === Fila['inicio'] && obj.fin === Fila['fin']);

                // Si se encontró el objeto, se elimina del arreglo
                if (index !== -1) {
                this.Disponibilidad[Dia].splice(index, 1);
                }
                //Se guarda resultado en base de datos
                this.Guardar();
            },
            Guardar(){
                // Se arma payload para la soliciutud

                const payload = {
                    idLaboratorio : JSON.parse(this.Laboratorio)['idLaboratorio'],
                    disponibilidad: this.Disponibilidad
                }

                // Se envia peticion a backend
                Axios.post(appsettings.Backend + this.Urls.ModificarDisponibilidad, payload)
                .then(response => {
                    // Se evalua respuesta del mismo 
                    if (response.data.ErrorOperacion == 1 || response.data.ErrorInesperado == 1) {
                        this.Disponibilidad = JSON.parse(this.Laboratorio)['disponibilidad']
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.Disponibilidad = JSON.parse(this.Laboratorio)['disponibilidad']
                })

            }
        }
    }

</script>

<style scoped>
.scroll {
  min-height: 270px;
  max-height: 290px; 
  overflow-y: auto;
}

.custom-tooltip {
  position: relative;
  margin-inline: 5px;
}
.custom-tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -30px;
  transform: translateX(-50%);
}
.custom-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: auto;
  bottom: -6px;
  border-top: 6px solid #333;
  border-bottom: none;
}
.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
  visibility: visible;
  opacity: 1;
}
.tablestyle {
    font-size: 12px;  
    text-align:center; 
    width: auto; 
    vertical-align: middle;
}
.center {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>